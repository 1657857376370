import { fmt } from 'IntlWrapper/IntlWrapper';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import BRButton from 'components/BRButton/BRButton';

import { isUserAuthorized } from 'utils/helpers';

import { ReactComponent as Edit } from 'assets/bosta-icons/Edit.svg';

export const ESCALATION_TABLE_COLUMNS = (handleOpenModal) => [
  {
    title: fmt({
      id: 'escalation.escalation_table_columns.order_id'
    }),
    dataIndex: 'order_id'
  },
  {
    title: fmt({
      id: 'escalation.escalation_table_columns.ticket_category'
    }),
    dataIndex: 'ticket_category'
  },
  {
    title: fmt({
      id: 'escalation.escalation_table_columns.recieved_hub'
    }),
    dataIndex: 'received_hub'
  },
  {
    title: fmt({
      id: 'escalation.escalation_table_columns.severity_level'
    }),
    dataIndex: 'severity_level'
  },
  {
    title: fmt({
      id: 'escalation.escalation_table_columns.ticket_number'
    }),
    dataIndex: 'ticket_number'
  },
  {
    title: fmt({
      id: 'escalation.escalation_table_columns.ticket_age'
    }),
    dataIndex: 'ticket_age'
  },
  {
    title: '',
    fixed: 'right',
    data_index: 'ticket',
    width: 60,
    render: (ticket) => {
      return (
        <BRButton
          onClick={() => handleOpenModal(ticket)}
          type="table-actions"
          className="button-sm"
          suffixIcon={<Edit />}
        />
      );
    }
  }
];

export const ESCALATION_QUICK_FILTER = [
  {
    label: 'All',
    value: 0
  },
  {
    label: fmt({
      id: 'escalation.escalation_severity_levels.severity_1'
    }),
    value: 1
  },
  {
    label: fmt({
      id: 'escalation.escalation_severity_levels.severity_2'
    }),
    value: 2
  },
  {
    label: fmt({
      id: 'escalation.escalation_severity_levels.severity_3'
    }),
    value: 3
  },
  {
    label: fmt({
      id: 'escalation.escalation_severity_levels.severity_4'
    }),
    value: 4
  },
  {
    label: fmt({
      id: 'escalation.escalation_severity_levels.severity_5'
    }),
    value: 5
  },
  {
    label: fmt({
      id: 'escalation.escalation_severity_levels.severity_6'
    }),
    value: 6
  }
];

export const SEVERITY_LEVEL_DROP_DOWN = ESCALATION_QUICK_FILTER.slice(1);
export const MAX_SEVERITY_LEVEL = 6;

export const TRANSFERS_AGING_TABLE_COLUMNS = (handleOpenModal) => [
  {
    title: fmt({
      id: 'escalation.transfers_aging.table_columns.tracking_number'
    }),
    dataIndex: 'trackingNumberLink'
  },
  {
    title: fmt({
      id: 'escalation.transfers_aging.table_columns.type'
    }),
    dataIndex: 'type'
  },
  {
    title: fmt({
      id: 'escalation.transfers_aging.table_columns.business_name'
    }),
    dataIndex: 'businessName'
  },
  {
    title: fmt({
      id: 'escalation.transfers_aging.table_columns.current_hub'
    }),
    dataIndex: 'currentHub'
  },
  {
    title: fmt({
      id: 'escalation.transfers_aging.table_columns.transferred_hub'
    }),
    dataIndex: 'transferredHub'
  },
  {
    title: fmt({
      id: 'escalation.transfers_aging.table_columns.no_of_transfers'
    }),
    dataIndex: 'inTransitTotalCount'
  },
  ,
  {
    title: fmt({
      id: 'escalation.transfers_aging.table_columns.order_aging'
    }),
    dataIndex: 'orderAging'
  },
  {
    title: '',
    fixed: 'right',
    data_index: 'ticket',
    width: 60,
    render: (ticket) => {
      return (
        <BRButton
          disabled={
            !isUserAuthorized(aclMatrix.DELIVERIES, [
              ACL_MATRIX.DELIVERY_FORCE_ASSIGN_TO_HUB
            ])
          }
          onClick={() => handleOpenModal(ticket)}
          type="table-actions"
          className="button-sm"
          suffixIcon={<Edit />}
        />
      );
    }
  }
];
