import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Collapse } from 'antd';
import classNames from 'classnames';

import { getSerials } from 'services/fulfillment';
import {
  RETURN_ORDER_STATE_IDS,
  goToNextStep,
  goToReturnOrdersList,
  handleChangeState,
  handlePrintSerials,
  renderSOName,
  isFulfillmentCRP,
  isFulfillmentExchangeReturn
} from 'constants/fulfillment-return-orders';
import { formatProductsFromSerials } from 'constants/fulfillment-inventory-serials';
import { openModal } from 'utils/modal';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';
import ProductImage from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/components/ProductImage/ProductImage';
import ScanInput from 'components/NewFulfillment/FulfillmentReturns/ScanSerialsModal/components/ScanInput/ScanInput';
import BRConfirmationModal from 'components/BRConfirmationModal/BRConfirmationModal';

import { ReactComponent as PrinterIcon } from 'assets/bosta-icons/Printer.svg';
import { ReactComponent as BarcodeIcon } from 'assets/bosta-icons/BarcodeIcon.svg';
import { ReactComponent as CheckedIcon } from 'assets/bosta-icons/Circle-Check.svg';
import WarningBoxImg from 'assets/imgRevamp/WarningBox.png';

import './ScanSerialsModal.less';

const { Panel } = Collapse;

const ScanSerialsModal = ({
  intl,
  roId,
  close,
  soId,
  originalSalesOrderId,
  products: productList,
  trackingNumber,
  type,
  ...restProps
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [productsObj, setProductsObj] = useState({});
  const [allFetchedSerials, setAllFetchedSerials] = useState([]);
  const [notScannedSerials, setNotScannedSerials] = useState([]);
  const [scannedSerials, setScannedSerials] = useState([]);

  const counts = [
    intl.formatMessage(
      {
        id: 'fulfillment_returns.scan_serials_modal.scanned_count'
      },
      {
        count: scannedSerials.length,
        total: allFetchedSerials.length
      }
    )
  ];

  const onConfirm = async () => {
    const scannedSerialsLinesObj = {};
    const notScannedSerialsLines = {};

    const formatLinesObj = (serials, obj) =>
      serials.forEach(({ roLineId, serialNumber }) => {
        const currLine = obj[roLineId];
        obj[roLineId] = currLine ? [...currLine, serialNumber] : [serialNumber];
      });

    formatLinesObj(scannedSerials, scannedSerialsLinesObj);
    formatLinesObj(notScannedSerials, notScannedSerialsLines);

    try {
      await handleChangeState({
        stateId: RETURN_ORDER_STATE_IDS.CONFIRMED,
        roId,
        lines: productList.map(({ id }) => {
          const unconfirmedSerials = notScannedSerialsLines[id] || [];
          const confirmedSerials = scannedSerialsLinesObj[id] || [];

          return {
            id,
            quantity: confirmedSerials.length,
            unconfirmedSerials,
            confirmedSerials
          };
        }),
        successModalProps: {
          title: isFulfillmentCRP(type)
            ? intl.formatMessage(
                {
                  id: 'fulfillment_returns.confirm_return_order.success_modal.tracking_number_title'
                },
                {
                  trackingNumber
                }
              )
            : intl.formatMessage(
                {
                  id: 'fulfillment_returns.confirm_return_order.success_modal.so_title'
                },
                {
                  soId: renderSOName(
                    isFulfillmentExchangeReturn(type)
                      ? originalSalesOrderId
                      : soId
                  )
                }
              ),
          confirmButtonLabel: intl.formatMessage({
            id: 'fulfillment_returns.confirm_return_order.success_modal.confirm_btn'
          }),
          cancelButtonLabel: intl.formatMessage({
            id: 'fulfillment_returns.confirm_return_order.success_modal.cancel_btn'
          }),
          modalAction: goToNextStep,
          cancelAction: goToReturnOrdersList,
          onCancel: null
        }
      });
      close();
    } catch (error) {
      notify(error.message);
    }
  };

  const confirmOnUserBeforeConfirmReturn = () => {
    openModal(BRConfirmationModal, {
      wrapClassName: 'br-confirm-po__confirmation-modal',
      icon: <img src={WarningBoxImg} alt="box" />,
      message: (
        <>
          <div className="br-print-serials-modal__unconfirmed-serials">
            {notScannedSerials.map(({ serialNumber }) => (
              <span>{serialNumber}</span>
            ))}
          </div>
          <span>
            {intl.formatMessage(
              {
                id: 'fulfillment_returns.scan_serials_modal.confirmation_modal.content'
              },
              {
                span: (children) => (
                  <span className="br-confirm-po__confirmation-modal__teal-color">{`${notScannedSerials.length} ${children}`}</span>
                )
              }
            )}
          </span>
        </>
      ),
      onConfirm,
      confirmButtonText: intl.formatMessage(
        {
          id: 'fulfillment_returns.scan_serials_modal.confirmation_modal.confirm_btn'
        },
        {
          count: notScannedSerials.length
        }
      ),
      cancelButtonText: intl.formatMessage({
        id: 'common.back'
      }),
      width: 430
    });
  };

  const renderFooter = () => {
    return (
      <>
        <div className="br-po-select-products-modal__footer__counts">
          {counts.map((count, index) => (
            <span key={index}>{count}</span>
          ))}
        </div>
        <div className="br-po-select-products-modal__footer__actions">
          <BRButton
            label={intl.formatMessage({
              id: 'common.cancel'
            })}
            onClick={close}
          />
          <BRButton
            type="primary"
            label={intl.formatMessage({
              id: 'fulfillment_returns.actions.confirm_return'
            })}
            loading={isLoading}
            onClick={
              notScannedSerials.length
                ? confirmOnUserBeforeConfirmReturn
                : onConfirm
            }
          />
        </div>
      </>
    );
  };

  const fetchSerials = async () => {
    setIsLoading(true);

    try {
      const payload = {
        roId
      };
      const { data } = await getSerials(payload);
      const products = formatProductsFromSerials({
        serials: data?.serials,
        returnAsObject: true
      });

      setProductsObj(products);
      setNotScannedSerials(
        data.serials?.map(({ roLineId, serialNumber, productSku }) => ({
          roLineId,
          serialNumber,
          productSku
        }))
      );
      setAllFetchedSerials(data.serials);
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  const renderSerial = ({ serial, isScanned = false }) => (
    <div key={serial} className="br-print-serials-modal__collapse__serial">
      <span
        className={classNames({
          scanned: isScanned
        })}
      >
        {serial}
        {isScanned && <CheckedIcon className="check-icon" />}
      </span>
      <PrinterIcon
        className="br-collapse__serial__icon"
        onClick={() =>
          handlePrintSerials({
            serials: [serial],
            setIsLoading
          })
        }
      />
    </div>
  );

  const renderProductsCollapse = () => {
    const productsObjKeys = Object.keys(productsObj);

    return (
      !!productsObjKeys.length && (
        <Collapse
          className="br-print-serials-modal__collapse"
          expandIconPosition="end"
          defaultActiveKey={productsObjKeys}
        >
          {productsObjKeys.map((key) => {
            const {
              productName,
              productNameAr,
              serialNumbers,
              productSku,
              defaultImage
            } = productsObj[key];

            let skuNotScannedSerialsCount = serialNumbers.length;
            serialNumbers.forEach((s) => {
              if (
                scannedSerials.find(({ serialNumber }) => serialNumber === s)
              ) {
                skuNotScannedSerialsCount--;
              }
            });

            const isAllSKUSerialsScanned =
              serialNumbers.length && !skuNotScannedSerialsCount;

            return (
              <Panel
                key={productSku}
                header={
                  <div className="br-collapse__header">
                    <div className="br-collapse__header__name">
                      <ProductImage src={defaultImage} />
                      {productName || productNameAr}
                    </div>
                    <div
                      className={classNames(
                        'br-collapse__header__scanned-count',
                        {
                          scanned: isAllSKUSerialsScanned
                        }
                      )}
                    >
                      {isAllSKUSerialsScanned ? (
                        <CheckedIcon className="check-icon" />
                      ) : (
                        <BarcodeIcon />
                      )}
                      <span>
                        {skuNotScannedSerialsCount}/{serialNumbers.length}
                      </span>
                    </div>
                  </div>
                }
              >
                {notScannedSerials.map(
                  ({ productSku: sku, serialNumber }) =>
                    productSku === sku && renderSerial({ serial: serialNumber })
                )}
                {scannedSerials.map(
                  ({ productSku: sku, serialNumber }) =>
                    productSku === sku &&
                    renderSerial({ serial: serialNumber, isScanned: true })
                )}
              </Panel>
            );
          })}
        </Collapse>
      )
    );
  };

  useEffect(() => {
    fetchSerials();
  }, []);

  return (
    <Modal
      {...restProps}
      wrapClassName="br-print-serials-modal br-po-select-products-modal"
      title={intl.formatMessage({
        id: 'fulfillment_returns.scan_serials_modal.title'
      })}
      footer={renderFooter()}
    >
      <LoadingWrapper
        loading={isLoading}
        className="br-print-serials-modal__loading-wrapper"
      >
        <ScanInput
          scannedSerials={scannedSerials}
          setScannedSerials={setScannedSerials}
          notScannedSerials={notScannedSerials}
          setNotScannedSerials={setNotScannedSerials}
          allFetchedSerials={allFetchedSerials}
        />
        {renderProductsCollapse()}
      </LoadingWrapper>
    </Modal>
  );
};

export default injectIntl(ScanSerialsModal);
