import { useState, useRef } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import { openModal } from 'utils/modal';

import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import SelectBusiness from 'components/NewFulfillment/FulfillmentInbound/components/SelectBusiness/SelectBusiness';
import ProductsTable from 'components/NewFulfillment/FulfillmentProducts/components/ProductsList/components/ProductsTable/ProductsTable';
import BRButton from 'components/BRButton/BRButton';
import AddProductModal from 'components/NewFulfillment/FulfillmentProducts/components/ProductsList/components/AddProductModal/AddProductModal';

import { ReactComponent as PlusIcon } from 'assets/bosta-icons/Plus.svg';

import './ProductsList.less';

const ProductsList = ({ intl }) => {
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [fetchedBusinesses, setFetchedBusinesses] = useState([]);

  const tableRef = useRef();

  const getBusinessName = (businessId) => {
    return (
      selectedBusiness?.name ||
      fetchedBusinesses.find(({ _id }) => businessId === _id)?.name
    );
  };

  const refreshTable = () => {
    tableRef?.current();
  };

  const handleOpenAddProductModal = () => {
    openModal(AddProductModal, {
      businesses: fetchedBusinesses,
      refreshTable
    });
  };

  return (
    <Container
      className="br-fulfillment-products"
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'fulfillment_products.title'
          })}
        />
      }
      content={
        <>
          <SelectBusiness
            className="br-fulfillment-products__select-business"
            label={intl.formatMessage({
              id: 'fulfillment_inbound.po_filters.business.label'
            })}
            placeholder={intl.formatMessage({
              id: 'fulfillment_inbound.po_filters.business.placeholder'
            })}
            selectedBusiness={selectedBusiness}
            setSelectedBusiness={setSelectedBusiness}
            setFetchedBusinesses={setFetchedBusinesses}
          />
          <div className="br-add-product-btn">
            <BRButton
              type="primary"
              label={intl.formatMessage({
                id: 'fulfillment_inbound.create_po.products.select_products_modal.add_product'
              })}
              prefixIcon={<PlusIcon />}
              onClick={handleOpenAddProductModal}
              disabled={!fetchedBusinesses.length}
            />
          </div>
          <ProductsTable
            getBusinessName={getBusinessName}
            selectedBusinessId={selectedBusiness?._id}
            tableRef={tableRef}
          />
        </>
      }
    />
  );
};

export default injectIntl(withRouter(ProductsList));
