import { useState } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import { getReturnOrdersStatistics } from 'services/fulfillment-return-orders';
import {
  RETURN_ORDER_STATE_IDS,
  shouldShowIssues
} from 'constants/fulfillment-return-orders';

import POStatistics from 'components/NewFulfillment/FulfillmentInbound/components/POList/components/POStatistics/POStatistics';
import POFilters from 'components/NewFulfillment/FulfillmentInbound/components/POList/components/POFilters/POFilters';
import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import TypeFilter from 'components/NewFulfillment/FulfillmentReturns/ReturnsList/components/TypeFilter/TypeFilter';
import IssuesFilter from 'components/NewFulfillment/FulfillmentReturns/ReturnsList/components/IssuesFilter/IssuesFilter';
import ReturnOrdersTable from 'components/NewFulfillment/FulfillmentReturns/ReturnsList/components/ReturnOrdersTable/ReturnOrdersTable';
import DateFilter from 'components/NewFulfillment/FulfillmentInbound/components/POList/components/DateFilter/DateFilter';

import { ReactComponent as ReturnsIcon } from 'assets/bosta-icons/ReturnsIcon.svg';
import { ReactComponent as QualityCheckIcon } from 'assets/bosta-icons/QualityCheckIcon.svg';
import { ReactComponent as ProductBoxIcon } from 'assets/bosta-icons/ProductBoxIcon.svg';
import { ReactComponent as CompletedIcon } from 'assets/bosta-icons/CompletedIcon.svg';

import './ReturnsList.less';

const ReturnsList = ({ intl }) => {
  const [returnOrdersFilters, setReturnOrderFilters] = useState({
    state: null
  });
  const [isFirstFetchDone, setIsFirstFetchDone] = useState(false);
  const [statisticsFilters, setStatisticsFilters] = useState({});

  const { ON_HOLD, CONFIRMED, READY_TO_PUTAWAY, PUTAWAY } =
    RETURN_ORDER_STATE_IDS;

  const getStatisticsListToRender = ({
    total,
    onHoldCount,
    confirmedCount,
    readyToPutAwayCount,
    putAwayCount
  }) => [
    {
      key: 'total',
      title: intl.formatMessage({
        id: 'fulfillment_returns.statistics.titles.total'
      }),
      content: total,
      stateIdFilterValue: null
    },
    {
      key: 'return',
      title: intl.formatMessage({
        id: 'fulfillment_returns.statistics.titles.return'
      }),
      content: onHoldCount,
      icon: <ReturnsIcon />,
      stateIdFilterValue: ON_HOLD
    },
    {
      key: 'qualityCheck',
      title: intl.formatMessage({
        id: 'fulfillment_returns.statistics.titles.quality_check'
      }),
      content: confirmedCount,
      icon: <QualityCheckIcon />,
      stateIdFilterValue: CONFIRMED
    },
    {
      key: 'putaway',
      title: intl.formatMessage({
        id: 'fulfillment_returns.statistics.titles.putaway'
      }),
      content: readyToPutAwayCount,
      icon: <ProductBoxIcon />,
      stateIdFilterValue: READY_TO_PUTAWAY
    },
    {
      key: 'completed',
      title: intl.formatMessage({
        id: 'fulfillment_returns.statistics.titles.completed'
      }),
      content: putAwayCount,
      icon: <CompletedIcon />,
      stateIdFilterValue: PUTAWAY
    }
  ];

  const updateFilters = (filter) => {
    if (isFirstFetchDone) {
      setReturnOrderFilters((prev) => ({
        ...prev,
        ...filter
      }));
    }
  };

  const updateStatisticsFilters = (filter) => {
    if (isFirstFetchDone) {
      setStatisticsFilters((prev) => ({
        ...prev,
        ...filter
      }));
    }
  };

  const renderExtraFilters = () => (
    <>
      <TypeFilter updateFilters={updateFilters} />
      {shouldShowIssues(returnOrdersFilters?.state) && (
        <IssuesFilter updateFilters={updateFilters} />
      )}
    </>
  );

  return (
    <Container
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'fulfillment_returns.title'
          })}
        />
      }
      content={
        <>
          <div className="br-fulfillment-return-list__date-filter-section">
            <DateFilter
              updateFilters={updateFilters}
              updateStatisticsFilters={updateStatisticsFilters}
            />
          </div>
          <POStatistics
            updateFilters={updateFilters}
            poFilters={returnOrdersFilters}
            fetchData={getReturnOrdersStatistics}
            getStatisticsListToRender={getStatisticsListToRender}
            statisticsFilters={statisticsFilters}
          />
          <POFilters
            updateFilters={updateFilters}
            renderExtraFilters={renderExtraFilters}
            hideDateFilter
          />
          <ReturnOrdersTable
            returnOrdersFilters={returnOrdersFilters}
            isFirstFetchDone={isFirstFetchDone}
            setIsFirstFetchDone={setIsFirstFetchDone}
          />
        </>
      }
    />
  );
};

export default injectIntl(withRouter(ReturnsList));
