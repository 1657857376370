import React from 'react';
import { fmt, intl } from 'IntlWrapper/IntlWrapper';

import DeliveryStateFormatter from 'utils/orderStatesLogsFormatter';
import { takePendingActions } from 'services/shipments';
import { ORDER_TYPES_VALUE } from 'constants/shipment-details';
import { PUDO_STATES } from './shipments';
import { INVESTIGATION_EXCEPTIONS_CODES } from './order-logs';

import { notify } from 'components/Notify/Notify';

const coveredZones = `https://bosta.freshdesk.com/${intl.locale}/support/solutions/articles/36000256735-%D8%A7%D9%84%D9%85%D9%86%D8%A7%D8%B7%D9%82-%D8%A7%D9%84%D9%85%D9%88%D8%AC%D9%88%D8%AF%D8%A9-%D8%AF%D8%A7%D8%AE%D9%84-%D8%AE%D8%A7%D8%B1%D8%AC-%D8%AA%D8%BA%D8%B7%D9%8A%D8%A9-%D8%A8%D9%88%D8%B3%D8%B7%D8%A9`;

const packingGuideLines = `https://bosta.freshdesk.com/${intl.locale}/support/solutions/articles/36000256737-%D8%A5%D8%B2%D8%A7%D9%8A-%D8%AA%D8%BA%D9%84%D9%81-%D8%A7%D9%88%D8%B1%D8%AF%D8%B1%D8%AA%D9%83-%D9%81%D9%8A-%D8%A8%D9%88%D8%B3%D8%B7%D8%A9`;
export const states = {
  // Send
  10: {
    10: {
      value: fmt({
        id: `orders.order_new_statues.new`
      }),
      description: ({ type, type_before }) =>
        fmt({
          id: `orders.states_description.send${
            type.value === ORDER_TYPES_VALUE.FXF_SEND ||
            type_before === ORDER_TYPES_VALUE.FXF_SEND
              ? '.fxf_send'
              : ''
          }.new10`
        })
    },
    10.1: {
      value: fmt({
        id: `orders.order_new_statues.rescheduled`
      }),
      description: ({ scheduledAt }) =>
        fmt(
          {
            id: `orders.states_description.send.101`
          },
          {
            day: DeliveryStateFormatter.formatSlaDate(scheduledAt)
          }
        )
    },
    10.2: {
      value: fmt({
        id: `orders.order_new_statues.rescheduled`
      }),
      description: ({ scheduledAt }) =>
        fmt(
          {
            id: `orders.states_description.send.102`
          },
          {
            day: DeliveryStateFormatter.formatSlaDate(scheduledAt)
          }
        )
    },
    10.3: {
      value: fmt({
        id: `orders.order_new_statues.pending_pickup`
      }),
      description: ({ state }) =>
        fmt(
          {
            id: `orders.states_description.send.103`
          },
          {
            pack: (
              <a
                href={packingGuideLines}
                target="_blank"
                rel="noreferrer noopener"
              >
                {fmt({ id: 'orders.states_description.packing_guidelines' })}
              </a>
            ),
            pickupDate: DeliveryStateFormatter._formatDate(
              state.nearestPickupDate,
              true
            )
          }
        )
    },
    10.4: {
      value: fmt({
        id: `orders.order_new_statues.rescheduled`
      }),
      description: () =>
        fmt(
          {
            id: `orders.states_description.send.103`
          },
          {
            pack: (
              <a
                href={packingGuideLines}
                target="_blank"
                rel="noreferrer noopener"
              >
                {fmt({ id: 'orders.states_description.packing_guidelines' })}
              </a>
            )
          }
        )
    },
    10.5: {
      value: fmt({
        id: `orders.order_new_statues.rescheduled`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.send.10`
        })
    },
    20: {
      value: fmt({
        id: `orders.order_new_statues.preparing_for_delivery`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.send.20`
        })
    },
    21: {
      value: fmt({
        id: `orders.order_new_statues.picked_up`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.send.21`
        })
    },
    24: {
      value: fmt({
        id: `orders.order_new_statues.in_transit`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.send.24`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    24.2: {
      value: fmt({
        id: `orders.order_new_statues.rescheduled`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.send.47.accept_4`
        })
    },
    24.3: {
      value: fmt({
        id: `orders.order_new_statues.rescheduled`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.send.47.address_change_5`
        })
    },
    24.4: {
      value: fmt({
        id: `orders.order_new_statues.rescheduled`
      }),
      description: ({ type, type_before }) =>
        fmt({
          id: `orders.states_description.send${
            type.value === ORDER_TYPES_VALUE.FXF_SEND ||
            type_before === ORDER_TYPES_VALUE.FXF_SEND
              ? '.fxf_send'
              : ''
          }.47.phone_change_5`
        })
    },
    25: {
      value: fmt({
        id: `orders.order_new_statues.fulfilled`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.fulfilled`
        })
    },
    30: {
      value: fmt({
        id: `orders.order_new_statues.in_transit`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.send.24`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    41: {
      value: fmt({
        id: `orders.order_new_statues.out_for_delivery`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.send.41`
        })
    },
    45: {
      value: fmt({
        id: `orders.order_new_statues.delivered`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.send.45`
        })
    },
    47: {
      1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: ({ type, type_before }) =>
          fmt({
            id: `orders.states_description.send${
              type.value === ORDER_TYPES_VALUE.FXF_SEND ||
              type_before === ORDER_TYPES_VALUE.FXF_SEND
                ? '.fxf_send'
                : ''
            }.47.1`
          })
      },
      2: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: ({ type, type_before }) =>
          fmt({
            id: `orders.states_description.send${
              type.value === ORDER_TYPES_VALUE.FXF_SEND ||
              type_before === ORDER_TYPES_VALUE.FXF_SEND
                ? '.fxf_send'
                : ''
            }.47.2`
          })
      },
      3: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: ({ scheduledAt, type, type_before }) =>
          fmt(
            {
              id: `orders.states_description.send${
                type.value === ORDER_TYPES_VALUE.FXF_SEND ||
                type_before === ORDER_TYPES_VALUE.FXF_SEND
                  ? '.fxf_send'
                  : ''
              }.47.3`
            },
            {
              newDate: DeliveryStateFormatter._formatDate(scheduledAt, true)
            }
          )
      },
      4: {
        value: fmt({
          id: `orders.order_new_statues.waiting_for_business_action`
        }),
        description: ({ sla }) =>
          fmt(
            {
              id: `orders.states_description.send.47.awaiting_4`
            },
            {
              day: sla?.orderSla?.orderSlaTimestamp
                ? DeliveryStateFormatter.formatSlaDate(
                    sla?.orderSla.orderSlaTimestamp,
                    true
                  )
                : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
            }
          )
      },
      4.1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.send.47.accept_4`
          })
      },
      13: {
        value: fmt({
          id: `orders.order_new_statues.waiting_for_business_action`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.send.47.address5`
          })
      },
      13.1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.send.47.address_change_5`
          })
      },
      13.2: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.send.47.address_not_changes_5`
          })
      },
      14: {
        value: fmt({
          id: `orders.order_new_statues.waiting_for_business_action`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.send.47.phone5`
          })
      },
      14.1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: ({ type, type_before }) =>
          fmt({
            id: `orders.states_description.send${
              type.value === ORDER_TYPES_VALUE.FXF_SEND ||
              type_before === ORDER_TYPES_VALUE.FXF_SEND
                ? '.fxf_send'
                : ''
            }.47.phone_change_5`
          })
      },
      14.2: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.send.47.phone_not_changes_5`
          })
      },
      6: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: ({ sla }) =>
          fmt(
            {
              id: `orders.states_description.send.47.6`
            },
            {
              day: sla?.orderSla?.orderSlaTimestamp
                ? DeliveryStateFormatter.formatSlaDate(
                    sla?.orderSla.orderSlaTimestamp,
                    true
                  )
                : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
            }
          )
      },
      7: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: ({ type, type_before }) =>
          fmt({
            id: `orders.states_description.send${
              type.value === ORDER_TYPES_VALUE.FXF_SEND ||
              type_before === ORDER_TYPES_VALUE.FXF_SEND
                ? '.fxf_send'
                : ''
            }.47.7`
          })
      },
      8: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: ({ sla }) =>
          fmt(
            {
              id: `orders.states_description.send.47.8`
            },
            {
              day: sla?.orderSla?.orderSlaTimestamp
                ? DeliveryStateFormatter.formatSlaDate(
                    sla?.orderSla.orderSlaTimestamp,
                    true
                  )
                : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
            }
          )
      },
      12: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: ({ sla, type, type_before }) =>
          fmt(
            {
              id: `orders.states_description.outbound_actions.10.${
                type.value === ORDER_TYPES_VALUE.FXF_SEND ||
                type_before === ORDER_TYPES_VALUE.FXF_SEND
                  ? 'fxf_uncovered_address'
                  : 'uncovered_address'
              }`
            },
            {
              day: DeliveryStateFormatter.formatSlaDate(
                sla?.orderSla?.orderSlaTimestamp || Date.now()
              ),
              checkCoveredZones: (
                <a
                  href={coveredZones}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {fmt({ id: 'orders.states_description.check_covered_zones' })}
                </a>
              )
            },
            {}
          )
      },
      default: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: (reason) => `Order received an exception:${reason}`
      }
    },
    49: {
      value: fmt({
        id: `orders.order_new_statues.on_hold`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.send.49`
          },
          {
            day: DeliveryStateFormatter.formatSlaDate(
              sla.orderSla.orderSlaTimestamp || Date.now()
            ),
            c: (
              <a href={coveredZones} target="_blank" rel="noreferrer noopener">
                {fmt({ id: 'orders.states_description.check_covered_zones' })}
              </a>
            )
          }
        )
    },
    49.1: {
      value: fmt({
        id: `orders.order_new_statues.on_hold`
      }),
      description: () =>
        fmt(
          {
            id: `orders.states_description.send.491`
          },
          {
            checkCoveredZones: (
              <a href={coveredZones} target="_blank" rel="noreferrer noopener">
                {fmt({ id: 'orders.states_description.check_covered_zones' })}
              </a>
            )
          }
        )
    }
  },
  // RTO
  20: {
    10.1: {
      value: fmt({
        id: `orders.order_new_statues.canceled`
      }),
      description: ({ sla, type, type_before }) =>
        fmt(
          {
            id: `orders.states_description.rto${
              type.value === ORDER_TYPES_VALUE.FXF_SEND ||
              type_before === ORDER_TYPES_VALUE.FXF_SEND
                ? '.fxf_rto'
                : ''
            }.24`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    20: {
      value: fmt({
        id: `orders.order_new_statues.preparing_for_return`
      }),
      description: ({ sla, type, type_before }) =>
        fmt(
          {
            id: `orders.states_description.rto${
              type.value === ORDER_TYPES_VALUE.FXF_SEND ||
              type_before === ORDER_TYPES_VALUE.FXF_SEND
                ? '.fxf_rto'
                : ''
            }.20`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    24: {
      value: fmt({
        id: `orders.order_new_statues.preparing_for_return`
      }),
      description: ({ sla, scheduledAt, type, type_before }) =>
        fmt(
          {
            id: `orders.states_description.rto${
              type.value === ORDER_TYPES_VALUE.FXF_SEND ||
              type_before === ORDER_TYPES_VALUE.FXF_SEND
                ? '.fxf_rto'
                : ''
            }.24`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    24.1: {
      value: fmt({
        id: `orders.order_new_statues.canceled`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.rto.201`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    30: {
      value: fmt({
        id: `orders.order_new_statues.canceled`
      }),
      description: ({ sla, type, type_before }) =>
        fmt(
          {
            id: `orders.states_description.rto${
              type.value === ORDER_TYPES_VALUE.FXF_SEND ||
              type_before === ORDER_TYPES_VALUE.FXF_SEND
                ? '.fxf_rto'
                : ''
            }.30`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    41: {
      value: fmt({
        id: `orders.order_new_statues.out_for_return`
      }),
      description: ({ type, type_before }) =>
        fmt({
          id: `orders.states_description.rto${
            type.value === ORDER_TYPES_VALUE.FXF_SEND ||
            type_before === ORDER_TYPES_VALUE.FXF_SEND
              ? '.fxf_rto'
              : ''
          }.41`
        })
    },
    46: {
      value: fmt({
        id: `orders.order_new_statues.returned_to_origin`
      }),
      description: ({ type, type_before }) =>
        fmt({
          id: `orders.states_description.rto${
            type.value === ORDER_TYPES_VALUE.FXF_SEND ||
            type_before === ORDER_TYPES_VALUE.FXF_SEND
              ? '.fxf_rto'
              : ''
          }.46`
        })
    },
    47: {
      20: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.rto.47.20`
          })
      },
      21: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: ({ scheduledAt }) =>
          fmt(
            {
              id: `orders.states_description.rto.47.21`
            },
            {
              newDate: DeliveryStateFormatter._formatDate(scheduledAt, true)
            }
          )
      },
      22: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.rto.47.22`
          })
      },
      23: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.rto.47.23`
          })
      },
      24: {
        value: fmt({
          id: `orders.order_new_statues.on_hold`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.rto.47.24`
          })
      },
      25: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.rto.47.25`
          })
      },
      default: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: (reason) => `Order received an exception: "${reason}"`
      }
    },
    49: {
      value: fmt({
        id: `orders.order_new_statues.canceled`
      }),
      description: ({ sla, type, type_before }) =>
        fmt(
          {
            id: `orders.states_description.outbound_actions.10.${
              type.value === ORDER_TYPES_VALUE.FXF_SEND ||
              type_before === ORDER_TYPES_VALUE.FXF_SEND
                ? 'fxf_uncovered_address'
                : 'uncovered_address'
            }`
          },
          {
            day: DeliveryStateFormatter.formatSlaDate(
              sla?.orderSla?.orderSlaTimestamp || Date.now(),
              true
            ),
            checkCoveredZones: (
              <a href={coveredZones} target="_blank" rel="noopener noreferrer">
                {fmt({ id: 'orders.states_description.check_covered_zones' })}
              </a>
            )
          },
          {}
        )
    },
    49.1: {
      value: fmt({
        id: `orders.order_new_statues.canceled`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.outbound_actions.30.uncovered_address`
          },
          {
            day: DeliveryStateFormatter.formatSlaDate(
              sla?.orderSla?.orderSlaTimestamp || Date.now(),
              true
            ),
            checkCoveredZones: (
              <a href={coveredZones} target="_blank" rel="noopener noreferrer">
                {fmt({ id: 'orders.states_description.check_covered_zones' })}
              </a>
            )
          },
          {}
        )
    },
    60: {
      value: fmt({
        id: `orders.order_new_statues.returned_to_stock`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.deliver_from_inventory.returned_to_stock`
        })
    }
  },

  destructive: (delivery) => {
    return {
      48: {
        value: fmt({
          id: `orders.order_new_statues.terminated`
        }),
        description:
          delivery?.holder?.role !== 'BUSINESS_ADMIN'
            ? delivery.numberOfAttempts >= 3
              ? fmt({
                  id: `orders.states_description.destructive.48`
                })
              : fmt({
                  id: `orders.states_description.destructive.482`
                })
            : fmt({
                id: `orders.states_description.destructive.481`
              })
      },
      48.1: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: fmt({
          id: `orders.states_description.outbound_actions.25.return_to_shipper`
        })
      },
      50: {
        value: fmt({
          id: `orders.order_new_statues.collection_failed`
        }),
        description:
          delivery?.holder?.role !== 'BUSINESS_ADMIN'
            ? delivery?.numberOfAttempts >= 3
              ? fmt({
                  id: `orders.states_description.destructive.48`
                })
              : fmt({
                  id: `orders.states_description.destructive.482`
                })
            : fmt({
                id: `orders.states_description.destructive.481`
              })
      },
      100: {
        value: fmt({
          id: `orders.order_new_statues.lost`
        }),
        description: fmt({
          id: `orders.states_description.destructive.100`
        })
      },
      101: {
        value: fmt({
          id: `orders.order_new_statues.damaged`
        }),
        description: fmt({
          id: `orders.states_description.destructive.101`
        })
      },
      104: {
        value: fmt({
          id: `orders.order_new_statues.archived`
        }),
        description: fmt({
          id: `orders.states_description.destructive.archive`
        })
      },
      103: {
        value: fmt({
          id: `orders.order_new_statues.waiting_for_business_action`
        }),
        description: fmt({
          id: `orders.states_description.destructive.103`
        })
      },
      103.1: {
        value: fmt({ id: `orders.order_new_statues.awaiting_your_action` }),
        description: fmt({
          id: `orders.states_description.destructive.103.1`
        })
      },
      105: {
        value: fmt({
          id: `orders.order_new_statues.on_hold`
        }),
        description: fmt(
          {
            id: `orders.states_description.destructive.105`
          },
          {
            ticketNumber: delivery?.tickets?.[delivery?.tickets?.length - 1]
          }
        )
      },
      105.1: {
        value: fmt({
          id: `orders.order_new_statues.on_hold`
        }),
        description: fmt({
          id: `orders.states_description.destructive.105.1`
        })
      },
      102: {
        value: fmt({
          id: `orders.order_new_statues.on_hold`
        }),
        description: INVESTIGATION_EXCEPTIONS_CODES.includes(
          delivery?.state?.lastExceptionCode
        )
          ? fmt({
              id: `orders.states_description.rto.47.26`
            })
          : fmt({
              id: `orders.states_description.destructive.102`
            })
      }
    };
  },
  // crp
  25: {
    10: {
      value: fmt({
        id: `orders.order_new_statues.ready_for_pickup`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.crp.10`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    10.1: {
      value: fmt({
        id: `orders.order_new_statues.rescheduled`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.crp.101`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    20: {
      value: fmt({
        id: `orders.order_new_statues.preparing_for_pickup`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.crp.20`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    20.1: {
      value: fmt({
        id: `orders.order_new_statues.preparing_for_return`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.rto.20`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    23: {
      value: fmt({
        id: `orders.order_new_statues.picked_up`
      }),
      description: () =>
        fmt(
          {
            id: `orders.states_description.crp.23`
          },
          {
            newDate: 'today'
          }
        )
    },
    22: {
      value: fmt({
        id: `orders.order_new_statues.out_for_pickup`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.crp.22`
        })
    },
    24: {
      value: fmt({
        id: `orders.order_new_statues.in_transit`
      }),
      description: ({ sla, type, type_before }) =>
        fmt(
          {
            id: `orders.states_description.rto${
              type.value === ORDER_TYPES_VALUE.FXF_SEND ||
              type_before === ORDER_TYPES_VALUE.FXF_SEND
                ? '.fxf_rto'
                : ''
            }.24`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    30: {
      value: fmt({
        id: `orders.order_new_statues.in_transit`
      }),
      description: ({ sla, type, type_before }) =>
        fmt(
          {
            id: `orders.states_description.rto${
              type.value === ORDER_TYPES_VALUE.FXF_SEND ||
              type_before === ORDER_TYPES_VALUE.FXF_SEND
                ? '.fxf_rto'
                : ''
            }.24`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    41: {
      value: fmt({
        id: `orders.order_new_statues.out_for_return`
      }),
      description: ({ type, type_before }) =>
        fmt({
          id: `orders.states_description.rto${
            type.value === ORDER_TYPES_VALUE.FXF_SEND ||
            type_before === ORDER_TYPES_VALUE.FXF_SEND
              ? '.fxf_rto'
              : ''
          }.41`
        })
    },
    46: {
      value: fmt({
        id: `orders.order_new_statues.returned`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.rto.46`
        })
    },
    47: {
      1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.crp.47.1`
          })
      },
      2: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.crp.47.2`
          })
      },
      3: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: ({ scheduledAt }) =>
          fmt(
            {
              id: `orders.states_description.crp.47.3`
            },
            {
              newDate: DeliveryStateFormatter._formatDate(scheduledAt, true)
            }
          )
      },
      13: {
        value: fmt({
          id: `orders.order_new_statues.waiting_for_business_action`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.crp.47.address5`
          })
      },
      13.1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.crp.47.address_change_5`
          })
      },
      14: {
        value: fmt({
          id: `orders.order_new_statues.waiting_for_business_action`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.crp.47.phone5`
          })
      },
      14.1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.crp.47.phone_change_5`
          })
      },
      13.2: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.crp.47.address_not_changes_5`
          })
      },
      14.2: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.crp.47.phone_not_changes_5`
          })
      },
      6: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.crp.47.6`
          })
      },
      7: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.crp.47.7`
          })
      },
      9: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.crp.47.9`
          })
      },
      12: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: ({ sla }) =>
          fmt(
            {
              id: `orders.states_description.outbound_actions.25.uncovered_address`
            },
            {
              day: DeliveryStateFormatter.formatSlaDate(
                sla?.orderSla?.orderSlaTimestamp || Date.now()
              ),
              checkCoveredZones: (
                <a
                  href={coveredZones}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {fmt({ id: 'orders.states_description.check_covered_zones' })}
                </a>
              )
            },
            {}
          )
      },

      default: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: (reason) => `Order received an exception: "${reason}"`
      }
    },
    49: {
      value: fmt({
        id: `orders.order_new_statues.on_hold`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.crp.49`
          },
          {
            c: (
              <a href={coveredZones} target="_blank" rel="noreferrer noopener">
                {fmt({ id: 'orders.states_description.check_covered_zones' })}
              </a>
            )
          }
        )
    },
    49.1: {
      value: fmt({
        id: `orders.order_new_statues.on_hold`
      }),
      description: () =>
        fmt(
          {
            id: `orders.states_description.crp.49`
          },
          {
            checkCoveredZones: (
              <a href={coveredZones} target="_blank" rel="noopener noreferrer">
                {fmt({ id: 'orders.states_description.check_covered_zones' })}
              </a>
            )
          }
        )
    },
    1: {
      value: fmt({
        id: `orders.order_new_statues.canceled`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.crp.c1`
        })
    }
  },
  //exchange
  30: {
    10: {
      value: fmt({
        id: `orders.order_new_statues.new`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.exchange.new10`
        })
    },
    10.1: {
      value: fmt({
        id: `orders.order_new_statues.rescheduled`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.exchange.101`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    10.2: {
      value: fmt({
        id: `orders.order_new_statues.rescheduled`
      }),
      description: ({ sla, type, type_before }) =>
        fmt(
          {
            id: `orders.states_description.exchange${
              type.value === ORDER_TYPES_VALUE.FXF_SEND ||
              type_before === ORDER_TYPES_VALUE.FXF_SEND
                ? '.fxf_exchange'
                : ''
            }.102`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    10.3: {
      value: fmt({
        id: `orders.order_new_statues.pending_pickup`
      }),
      description: ({ state }) =>
        fmt(
          {
            id: `orders.states_description.exchange.103`
          },
          {
            pack: (
              <a
                href={packingGuideLines}
                target="_blank"
                rel="noreferrer noopener"
              >
                {fmt({ id: 'orders.states_description.packing_guidelines' })}
              </a>
            ),
            pickupDate: DeliveryStateFormatter._formatDate(
              state.nearestPickupDate,
              true
            )
          }
        )
    },
    10.4: {
      value: fmt({
        id: `orders.order_new_statues.rescheduled`
      }),
      description: () =>
        fmt(
          {
            id: `orders.states_description.exchange.103`
          },
          {
            pack: (
              <a
                href={packingGuideLines}
                target="_blank"
                rel="noreferrer noopener"
              >
                {fmt({ id: 'orders.states_description.packing_guidelines' })}
              </a>
            )
          }
        )
    },
    10.5: {
      value: fmt({
        id: `orders.order_new_statues.rescheduled`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.exchange.10`
        })
    },
    20: {
      value: fmt({
        id: `orders.order_new_statues.preparing_for_exchange`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.exchange.20`
        })
    },
    21: {
      value: fmt({
        id: `orders.order_new_statues.picked_up`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.exchange.21`
        })
    },
    23: {
      value: fmt({
        id: `orders.order_new_statues.exchanged`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.exchange.23`
        })
    },
    24: {
      value: fmt({
        id: `orders.order_new_statues.in_transit`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.exchange.24`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    24.2: {
      value: fmt({
        id: `orders.order_new_statues.rescheduled`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.exchange.47.accept_4`
        })
    },
    24.3: {
      value: fmt({
        id: `orders.order_new_statues.rescheduled`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.exchange.47.address_change_5`
        })
    },
    24.4: {
      value: fmt({
        id: `orders.order_new_statues.rescheduled`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.exchange.47.phone_change_5`
        })
    },
    30: {
      value: fmt({
        id: `orders.order_new_statues.in_transit`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.exchange.30`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    41: {
      value: fmt({
        id: `orders.order_new_statues.out_for_exchange`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.exchange.41`
        })
    },
    41.1: {
      value: fmt({
        id: `orders.order_new_statues.out_for_return`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.rto.41`
        })
    },
    // 45: fmt({
    //   id: 'deliveries.states.deliverd'
    // }),
    // 46: {
    //   value: fmt({
    //     id: `orders.order_new_statues.returned`
    //   }),
    //   description: () =>
    //     fmt({
    //       id: `orders.states_description.rto.46`
    //     })
    // },
    46: {
      value: fmt({
        id: `orders.order_new_statues.exchanged_returned`
      }),
      description: ({ type, type_before }) =>
        fmt({
          id: `orders.states_description.rto${
            type.value === ORDER_TYPES_VALUE.FXF_SEND ||
            type_before === ORDER_TYPES_VALUE.FXF_SEND
              ? '.fxf_rto'
              : ''
          }.46`
        })
    },
    47: {
      1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.exchange.47.1`
          })
      },
      2: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.exchange.47.2`
          })
      },
      3: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: ({ scheduledAt }) =>
          fmt(
            {
              id: `orders.states_description.exchange.47.3`
            },
            {
              newDate: DeliveryStateFormatter._formatDate(scheduledAt, true)
            }
          )
      },
      4: {
        value: fmt({
          id: `orders.order_new_statues.waiting_for_business_action`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.exchange.47.awaiting_4`
          })
      },
      4.1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.exchange.47.accept_4`
          })
      },
      13: {
        value: fmt({
          id: `orders.order_new_statues.waiting_for_business_action`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.exchange.47.address5`
          })
      },
      13.1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.exchange.47.address_change_5`
          })
      },
      14: {
        value: fmt({
          id: `orders.order_new_statues.waiting_for_business_action`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.exchange.47.phone5`
          })
      },
      14.1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.exchange.47.phone_change_5`
          })
      },
      13.2: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.exchange.47.address_not_changes_5`
          })
      },
      14.2: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.exchange.47.phone_not_changes_5`
          })
      },
      6: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: ({ sla }) =>
          fmt(
            {
              id: `orders.states_description.exchange.47.6`
            },
            {
              day: sla?.orderSla?.orderSlaTimestamp
                ? DeliveryStateFormatter.formatSlaDate(
                    sla?.orderSla.orderSlaTimestamp,
                    true
                  )
                : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
            }
          )
      },
      7: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.exchange.47.7`
          })
      },
      8: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: ({ sla }) =>
          fmt(
            {
              id: `orders.states_description.exchange.47.8`
            },
            {
              day: sla?.orderSla?.orderSlaTimestamp
                ? DeliveryStateFormatter.formatSlaDate(
                    sla?.orderSla.orderSlaTimestamp,
                    true
                  )
                : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
            }
          )
      },
      20: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.rto.47.20`
          })
      },

      21: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: ({ scheduledAt }) =>
          fmt(
            {
              id: `orders.states_description.rto.47.21`
            },
            {
              newDate: DeliveryStateFormatter._formatDate(scheduledAt, true)
            }
          )
      },
      22: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.rto.47.22`
          })
      },
      23: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.rto.47.23`
          })
      },
      24: {
        value: fmt({
          id: `orders.order_new_statues.on_hold`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.rto.47.24`
          })
      },
      25: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.rto.47.25`
          })
      },
      12: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: ({ sla }) =>
          fmt(
            {
              id: `orders.states_description.outbound_actions.30.uncovered_address`
            },
            {
              day: DeliveryStateFormatter.formatSlaDate(
                sla?.orderSla?.orderSlaTimestamp || Date.now()
              ),
              checkCoveredZones: (
                <a
                  href={coveredZones}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {fmt({ id: 'orders.states_description.check_covered_zones' })}
                </a>
              )
            },
            {}
          )
      },

      default: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: (reason) => `Order received an exception: "${reason}"`
      }
    },
    49.2: {
      value: fmt({
        id: `orders.order_new_statues.canceled`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.exchange.49`
          },
          {
            day: DeliveryStateFormatter.formatSlaDate(
              sla.orderSla.orderSlaTimestamp || Date.now()
            ),
            checkCoveredZones: (
              <a href={coveredZones} target="_blank" rel="noreferrer noopener">
                {fmt({ id: 'orders.states_description.check_covered_zones' })}
              </a>
            )
          }
        )
    },
    49.3: {
      value: fmt({
        id: `orders.order_new_statues.canceled`
      }),
      description: () =>
        fmt(
          {
            id: `orders.states_description.exchange.491`
          },
          {
            checkCoveredZones: (
              <a href={coveredZones} target="_blank" rel="noreferrer noopener">
                {fmt({ id: 'orders.states_description.check_covered_zones' })}
              </a>
            )
          }
        )
    }
  },
  // cash collection
  15: {
    10.1: {
      value: fmt({
        id: `orders.order_new_statues.rescheduled`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.cash_collection.101`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    11: {
      value: fmt({
        id: `orders.order_new_statues.ready_for_collection`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.cash_collection.11`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    20: {
      value: 'Preparing for collection',
      description: () =>
        fmt({
          id: `orders.states_description.cash_collection.20`
        })
    },
    40: {
      value: fmt({
        id: `orders.order_new_statues.out_for_collection`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.cash_collection.40`
        })
    },
    45: {
      value: fmt({
        id: `orders.order_new_statues.collected`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.cash_collection.45`
        })
    },
    47: {
      1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.cash_collection.47.1`
          })
      },
      2: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.cash_collection.47.2`
          })
      },
      3: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: ({ scheduledAt }) =>
          fmt(
            {
              id: `orders.states_description.cash_collection.47.3`
            },
            {
              newDate: DeliveryStateFormatter._formatDate(scheduledAt, true)
            }
          )
      },
      13: {
        value: fmt({
          id: `orders.order_new_statues.waiting_for_business_action`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.cash_collection.47.address5`
          })
      },
      13.1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.cash_collection.47.address_change_5`
          })
      },
      14: {
        value: fmt({
          id: `orders.order_new_statues.waiting_for_business_action`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.cash_collection.47.phone5`
          })
      },
      14.1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.cash_collection.47.phone_change_5`
          })
      },
      13.2: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.cash_collection.47.address_not_changes_5`
          })
      },
      14.2: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.cash_collection.47.phone_not_changes_5`
          })
      },
      6: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.cash_collection.47.6`
          })
      },
      7: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.cash_collection.47.7`
          })
      },
      10: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.cash_collection.47.10`
          })
      },
      12: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: ({ sla }) =>
          fmt(
            {
              id: `orders.states_description.outbound_actions.15.uncovered_address`
            },
            {
              day: DeliveryStateFormatter.formatSlaDate(
                sla?.orderSla?.orderSlaTimestamp || Date.now()
              ),
              checkCoveredZones: (
                <a
                  href={coveredZones}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {fmt({ id: 'orders.states_description.check_covered_zones' })}
                </a>
              )
            },
            {}
          )
      },
      default: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: (reason) => `Order received an exception: "${reason}"`
      }
    },
    49: {
      value: fmt({
        id: `orders.order_new_statues.on_hold`
      }),
      description: () =>
        fmt(
          {
            id: `orders.states_description.cash_collection.49`
          },
          {
            checkCoveredZones: (
              <a href={coveredZones} target="_blank" rel="noreferrer noopener">
                {fmt({ id: 'orders.states_description.check_covered_zones' })}
              </a>
            )
          }
        )
    }
  },
  // Sign&return
  35: {
    43: {
      value: fmt({
        id: `orders.order_new_statues.pending_customer_signature`
      }),
      description: ({ scheduledAt }) =>
        fmt(
          {
            id: `orders.states_description.sign_and_return.43`
          },
          {
            pickupDate: DeliveryStateFormatter._formatDate(scheduledAt, true)
          }
        )
    },
    42: {
      value: fmt({
        id: `orders.order_new_statues.pending_customer_signature`
      }),
      description: ({ scheduledAt }) =>
        fmt(
          {
            id: `orders.states_description.sign_and_return.43`
          },
          {
            pickupDate: DeliveryStateFormatter._formatDate(scheduledAt, true)
          }
        )
    },
    10: {
      value: fmt({
        id: `orders.order_new_statues.new`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.sign_and_return.new10`
        })
    },
    10.1: {
      value: fmt({
        id: `orders.order_new_statues.rescheduled`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.sign_and_return.101`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    10.2: {
      value: fmt({
        id: `orders.order_new_statues.rescheduled`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.sign_and_return.102`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    10.3: {
      value: fmt({
        id: `orders.order_new_statues.pending_pickup`
      }),
      description: ({ state }) =>
        fmt(
          {
            id: `orders.states_description.sign_and_return.103`
          },
          {
            pack: (
              <a
                href={packingGuideLines}
                target="_blank"
                rel="noreferrer noopener"
              >
                {fmt({ id: 'orders.states_description.packing_guidelines' })}
              </a>
            ),
            pickupDate: DeliveryStateFormatter._formatDate(
              state.nearestPickupDate,
              true
            )
          }
        )
    },

    20: {
      value: fmt({
        id: `orders.order_new_statues.preparing_for_delivery`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.sign_and_return.20`
        })
    },
    20.1: {
      value: fmt({
        id: `orders.order_new_statues.preparing_for_return`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.rto.20`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    20.2: {
      value: fmt({
        id: `orders.order_new_statues.preparing_for_pickup`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.sign_and_return.202`
        })
    },
    21: {
      value: fmt({
        id: `orders.order_new_statues.picked_up`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.sign_and_return.21`
        })
    },
    24: {
      value: fmt({
        id: `orders.order_new_statues.in_transit`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.sign_and_return.24`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },

    30: {
      value: fmt({
        id: `orders.order_new_statues.in_transit`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.sign_and_return.24`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    22: {
      value: fmt({
        id: `orders.order_new_statues.out_for_signature`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.sign_and_return.22`
        })
    },
    22.1: {
      value: fmt({
        id: `orders.order_new_statues.out_for_pickup`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.sign_and_return.221`
        })
    },
    41.1: {
      value: fmt({
        id: `orders.order_new_statues.out_for_pickup`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.sign_and_return.411`
        })
    },
    23: {
      value: fmt({
        id: `orders.order_new_statues.signed_and_collected`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.sign_and_return.23`
        })
    },
    23.1: {
      value: fmt({
        id: `orders.order_new_statues.signed_and_collected`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.sign_and_return.231`
        })
    },
    24.5: {
      value: fmt({
        id: `orders.order_new_statues.in_transit`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.rto.24`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    30.1: {
      value: fmt({
        id: `orders.order_new_statues.in_transit`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.rto.24`
          },
          {
            day: sla?.orderSla?.orderSlaTimestamp
              ? DeliveryStateFormatter.formatSlaDate(
                  sla?.orderSla.orderSlaTimestamp,
                  true
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
          }
        )
    },
    41: {
      value: fmt({
        id: `orders.order_new_statues.out_for_return`
      }),
      description: ({ type, type_before }) =>
        fmt({
          id: `orders.states_description.rto${
            type.value === ORDER_TYPES_VALUE.FXF_SEND ||
            type_before === ORDER_TYPES_VALUE.FXF_SEND
              ? '.fxf_rto'
              : ''
          }.41`
        })
    },
    46: {
      value: fmt({
        id: `orders.order_new_statues.signed_and_returned`
      }),
      description: () =>
        fmt({
          id: `orders.states_description.sign_and_return.46`
        })
    },
    47: {
      1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.47.1`
          })
      },
      2: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.47.2`
          })
      },
      3: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: ({ scheduledAt }) =>
          fmt(
            {
              id: `orders.states_description.sign_and_return.47.3`
            },
            {
              newDate: DeliveryStateFormatter._formatDate(scheduledAt, true)
            }
          )
      },
      4: {
        value: fmt({
          id: `orders.order_new_statues.waiting_for_business_action`
        }),
        description: ({ sla }) =>
          fmt(
            {
              id: `orders.states_description.sign_and_return.47.awaiting_4`
            },
            {
              day: sla?.orderSla?.orderSlaTimestamp
                ? DeliveryStateFormatter.formatSlaDate(
                    sla?.orderSla.orderSlaTimestamp,
                    true
                  )
                : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
            }
          )
      },
      4.1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.47.accept_4`
          })
      },
      13: {
        value: fmt({
          id: `orders.order_new_statues.waiting_for_business_action`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.47.address5`
          })
      },
      13.1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.47.address_change_5`
          })
      },
      13.2: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.47.address_not_changes_5`
          })
      },
      14: {
        value: fmt({
          id: `orders.order_new_statues.waiting_for_business_action`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.47.phone5`
          })
      },
      14.1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.47.phone_change_5`
          })
      },
      14.2: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.47.phone_not_changes_5`
          })
      },
      6: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: ({ sla }) =>
          fmt(
            {
              id: `orders.states_description.sign_and_return.47.6`
            },
            {
              day: sla?.orderSla?.orderSlaTimestamp
                ? DeliveryStateFormatter.formatSlaDate(
                    sla?.orderSla.orderSlaTimestamp,
                    true
                  )
                : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
            }
          )
      },
      7: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.47.7`
          })
      },
      8: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: ({ sla }) =>
          fmt(
            {
              id: `orders.states_description.sign_and_return.47.8`
            },
            {
              day: sla?.orderSla?.orderSlaTimestamp
                ? DeliveryStateFormatter.formatSlaDate(
                    sla?.orderSla.orderSlaTimestamp,
                    true
                  )
                : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
            }
          )
      },
      12: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: ({ sla, type, type_before }) =>
          fmt(
            {
              id: `orders.states_description.outbound_actions.10.${
                type.value === ORDER_TYPES_VALUE.FXF_SEND ||
                type_before === ORDER_TYPES_VALUE.FXF_SEND
                  ? 'fxf_uncovered_address'
                  : 'uncovered_address'
              }`
            },
            {
              day: DeliveryStateFormatter.formatSlaDate(
                sla?.orderSla?.orderSlaTimestamp || Date.now()
              ),
              checkCoveredZones: (
                <a
                  href={coveredZones}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {fmt({ id: 'orders.states_description.check_covered_zones' })}
                </a>
              )
            },
            {}
          )
      },
      default: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: (reason) => `Order received an exception:${reason}`
      }
    },
    47.1: {
      1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.471.1`
          })
      },
      2: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.471.2`
          })
      },
      3: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: ({ scheduledAt }) =>
          fmt(
            {
              id: `orders.states_description.sign_and_return.471.3`
            },
            {
              newDate: DeliveryStateFormatter._formatDate(scheduledAt, true)
            }
          )
      },
      4: {
        value: fmt({
          id: `orders.order_new_statues.waiting_for_business_action`
        }),
        description: ({ sla }) =>
          fmt(
            {
              id: `orders.states_description.sign_and_return.471.awaiting_4`
            },
            {
              day: sla?.orderSla?.orderSlaTimestamp
                ? DeliveryStateFormatter.formatSlaDate(
                    sla?.orderSla.orderSlaTimestamp,
                    true
                  )
                : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
            }
          )
      },
      4.1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.471.accept_4`
          })
      },
      13: {
        value: fmt({
          id: `orders.order_new_statues.waiting_for_business_action`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.471.address5`
          })
      },
      13.1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.471.address_change_5`
          })
      },
      13.2: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.471.address_not_changes_5`
          })
      },
      14: {
        value: fmt({
          id: `orders.order_new_statues.waiting_for_business_action`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.471.phone5`
          })
      },
      14.1: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.471.phone_change_5`
          })
      },
      14.2: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.471.phone_not_changes_5`
          })
      },
      6: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: ({ sla }) =>
          fmt(
            {
              id: `orders.states_description.sign_and_return.471.6`
            },
            {
              day: sla?.orderSla?.orderSlaTimestamp
                ? DeliveryStateFormatter.formatSlaDate(
                    sla?.orderSla.orderSlaTimestamp,
                    true
                  )
                : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
            }
          )
      },
      7: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.471.7`
          })
      },
      8: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: ({ sla }) =>
          fmt(
            {
              id: `orders.states_description.sign_and_return.471.8`
            },
            {
              day: sla?.orderSla?.orderSlaTimestamp
                ? DeliveryStateFormatter.formatSlaDate(
                    sla?.orderSla.orderSlaTimestamp,
                    true
                  )
                : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
            }
          )
      },
      9: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: () =>
          fmt({
            id: `orders.states_description.sign_and_return.471.9`
          })
      },
      12: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: ({ sla, type, type_before }) =>
          fmt(
            {
              id: `orders.states_description.outbound_actions.10.${
                type.value === ORDER_TYPES_VALUE.FXF_SEND ||
                type_before === ORDER_TYPES_VALUE.FXF_SEND
                  ? 'fxf_uncovered_address'
                  : 'uncovered_address'
              }`
            },
            {
              day: DeliveryStateFormatter.formatSlaDate(
                sla?.orderSla?.orderSlaTimestamp || Date.now()
              ),
              checkCoveredZones: (
                <a
                  href={coveredZones}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {fmt({ id: 'orders.states_description.check_covered_zones' })}
                </a>
              )
            },
            {}
          )
      },
      default: {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: (reason) => `Order received an exception:${reason}`
      }
    },
    49: {
      value: fmt({
        id: `orders.order_new_statues.on_hold`
      }),
      description: ({ sla }) =>
        fmt(
          {
            id: `orders.states_description.sign_and_return.49`
          },
          {
            day: sla
              ? DeliveryStateFormatter.formatSlaDate(
                  sla.orderSla.orderSlaTimestamp
                )
              : DeliveryStateFormatter.formatSlaDate(Date.now()),
            c: (
              <a href={coveredZones} target="_blank" rel="noreferrer noopener">
                {fmt({ id: 'orders.states_description.check_covered_zones' })}
              </a>
            )
          }
        )
    },
    49.1: {
      value: fmt({
        id: `orders.order_new_statues.on_hold`
      }),
      description: () =>
        fmt(
          {
            id: `orders.states_description.send.491`
          },
          {
            checkCoveredZones: (
              <a href={coveredZones} target="_blank" rel="noreferrer noopener">
                {fmt({ id: 'orders.states_description.check_covered_zones' })}
              </a>
            )
          }
        )
    }
  },
  OUTBOUND_ACTIONS: (delivery) => {
    return {
      12: {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: fmt(
          {
            id: `orders.states_description.outbound_actions.${delivery.type.code}.uncovered_address`
          },
          {
            day: DeliveryStateFormatter.formatSlaDate(
              delivery.sla?.orderSla?.orderSlaTimestamp || Date.now()
            ),
            checkCoveredZones: (
              <a href={coveredZones} target="_blank" rel="noopener noreferrer">
                {fmt({ id: 'orders.states_description.check_covered_zones' })}
              </a>
            )
          },
          {}
        )
      },
      'Redispatch tomorrow': {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: fmt(
          {
            id: `orders.states_description.outbound_actions.${delivery.type.code}.reschdule_to_tomm_or_new_date`
          },
          {
            newDate: DeliveryStateFormatter._formatDate(
              delivery.exceptionOutboundAction?.scheduledAt ||
                delivery.outboundActions[0]?.scheduledAt,
              true
            )
          }
        )
      },
      'Return to shipper': {
        value: fmt({
          id: `orders.order_new_statues.canceled`
        }),
        description: ({ sla, type, type_before }) =>
          fmt(
            {
              id: `orders.states_description.outbound_actions.${
                delivery.type.code
              }.${
                (type.value === ORDER_TYPES_VALUE.FXF_SEND ||
                  type_before === ORDER_TYPES_VALUE.FXF_SEND) &&
                delivery.type.code === 20
                  ? 'fxf_return_to_shipper'
                  : 'return_to_shipper'
              }`
            },
            {
              day: sla?.orderSla?.orderSlaTimestamp
                ? DeliveryStateFormatter.formatSlaDate(
                    sla?.orderSla.orderSlaTimestamp,
                    true
                  )
                : DeliveryStateFormatter.formatSlaDate(Date.now(), true)
            }
          )
      },
      'Schedule delivery': {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: fmt(
          {
            id: `orders.states_description.outbound_actions.${delivery.type.code}.reschdule_to_tomm_or_new_date`
          },
          {
            newDate: DeliveryStateFormatter._formatDate(
              delivery.exceptionOutboundAction?.scheduledAt ||
                delivery.outboundActions[0]?.scheduledAt,
              true
            )
          }
        )
      },
      'Change delivery address': {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: fmt(
          {
            id: `orders.states_description.outbound_actions.${delivery.type.code}.address_update_and_reschdule`
          },
          {
            newDate: DeliveryStateFormatter._formatDate(
              delivery.exceptionOutboundAction?.scheduledAt ||
                delivery.outboundActions[0]?.scheduledAt,
              true
            )
          }
        )
      },
      'Change delivery address and Reschedule': {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: fmt(
          {
            id: `orders.states_description.outbound_actions.${delivery.type.code}.address_update_and_reschdule`
          },
          {
            newDate: DeliveryStateFormatter._formatDate(
              delivery.exceptionOutboundAction?.scheduledAt ||
                delivery.outboundActions[0]?.scheduledAt,
              true
            )
          }
        )
      },

      'Customer change phone number': {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: fmt(
          {
            id: `orders.states_description.outbound_actions.${delivery.type.code}.phone_update_and_reschdule`
          },
          {
            newDate: DeliveryStateFormatter._formatDate(
              delivery.exceptionOutboundAction?.scheduledAt ||
                delivery.outboundActions[0]?.scheduledAt,
              true
            )
          }
        )
      },
      "We Tried to call your customer 1 Times, But he didn't Reply": {
        value: fmt({
          id: `orders.order_new_statues.rescheduled`
        }),
        description: fmt({
          id: `orders.states_description.outbound_actions.${delivery.type.code}.customer_not_answering`
        })
      }
    };
  }
};
/**
 * 10 => new
 * 10.3 => pending pickup
 * 11 =>
 */
export const stateColor = {
  9: 'br-state-teal-group',
  10: 'br-state-blue-group',
  10.3: 'br-state-blue-group',
  11: 'br-state-teal-group',
  20: 'br-state-blue-group',
  20.1: 'br-state-blue-group',
  21: 'br-state-blue-group',
  22: 'br-state-blue-group',
  43: 'br-state-blue-group',
  42: 'br-state-blue-group',
  22.1: 'br-state-blue-group',
  23: 'br-state-blue-group',
  23.1: 'br-state-blue-group',
  24: 'br-state-blue-group',
  [PUDO_STATES.RECEIVED_AT_WAREHOUSE]: 'br-state-blue-group',
  24.5: 'br-state-blue-group',
  25: 'br-state-blue-group',
  30: 'br-state-blue-group',
  30.1: 'br-state-blue-group',
  40: 'br-state-blue-group',
  41: 'br-state-blue-group',
  [PUDO_STATES.READY_TO_PICK]: 'br-state-blue-group',
  41.1: 'br-state-blue-group',
  24.1: 'br-state-gray-group',
  24.2: 'br-state-yellow-group',
  24.3: 'br-state-yellow-group',
  24.4: 'br-state-yellow-group',
  45: 'br-state-green-group',
  [PUDO_STATES.DELIVERD]: 'br-state-green-group',
  46: 'br-state-green-group',
  [PUDO_STATES.CLOSED]: 'br-state-grey-group',
  47: 'br-state-yellow-group',
  47.1: 'br-state-yellow-group',
  2: 'br-state-yellow-group',
  102: 'br-state-onhold-yellow-group',
  10.4: 'br-state-yellow-group',
  10.5: 'br-state-yellow-group',
  103: 'br-state-yellow-group',
  103.1: 'br-state-yellow-group',
  10.1: 'br-state-yellow-group',
  10.2: 'br-state-yellow-group',
  102: 'br-state-yellow-group',
  49: 'br-state-yellow-group',
  49.1: 'br-state-yellow-group',
  49.2: 'br-state-gray-group',
  49.3: 'br-state-gray-group',
  1: 'br-state-gray-group',
  48: 'br-state-gray-group',
  48.1: 'br-state-gray-group',
  50: 'br-state-gray-group',
  104: 'br-state-gray-group',
  100: 'br-state-red-group',
  101: 'br-state-red-group',
  25: 'br-state-blue-group',
  60: 'br-state-gray-group'
};

export const ACTION_STATES = {
  10: {
    buttonTitle: fmt({
      id: `orders.states_description.request_pickup`
    }),
    buttonOnClick: (history) => history.push('/pickups/create')
  },
  13: {
    buttonTitle: fmt({
      id: `orders.states_description.update_address`
    }),
    buttonOnClick: (history) => history.push('/pickups/create')
  },
  14: {
    buttonTitle: fmt({
      id: `orders.states_description.update_phone`
    }),
    buttonOnClick: (history) => history.push('/pickups/create')
  },
  4: {
    firstButtonTitle: fmt({
      id: `common.allow`
    }),
    secondButtonTitle: fmt({
      id: `common.return`
    }),
    firstButtonOnClick: (id, payload, tn) => sendPendingAction(id, payload, tn),
    secondButtonOnClick: (id, payload, tn) => sendPendingAction(id, payload, tn)
  }
};

export const sendPendingAction = async (id, payload, tn) => {
  try {
    await takePendingActions(id, payload);
    notify(
      fmt(
        {
          id: `orders.states_description.success_message`
        },
        {
          tn: (
            <span
              className="br-order-description__actionable-success"
              onClick={() => {
                window.open(`/order/${tn}`, '_blank');
              }}
            >{`#${tn}`}</span>
          )
        }
      ),
      'success'
    );
  } catch (err) {
    notify(err.message);
  }
};

export const SIGN_AND_RETURN_PICKUP = 'Sign and Return Pickup';

export const TIMELINE_STATES = {
  NEW: 0,
  PROCESSING: 1,
  COMPLETED: 2
};

export const STATES = {
  NEW: [10, 11],
  COMPLETED: [45, 46, 48, 50, 60, 100, 101, 104]
};
