import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Button, Menu, Dropdown, Tag } from 'antd';
import { CaretDownOutlined, RiseOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import Icon from '@ant-design/icons';
import { isUserAuthorized, isDefaultBostaCourier } from 'utils/helpers';
import { openModal } from 'utils/modal';
import { printAirWayBill } from 'actions/DeliveriesActions';
import { showBill } from 'utils';
import { downloadAsPdf } from 'utils/download';
import {
  formatSla,
  formatDate,
  handleOpenRateStarModal
} from 'utils/shipmentDetails';
import { lockUnlockDeliveryItem } from 'services/live-ops';
import {
  COMPLETED_ORDERS_REACHES_FINAL_STATES_CODES,
  ORDER_EDITABLE_STATES_CODE
} from 'constants/hubs';
import {
  EXCEPTION,
  ORDER_TYPES_VALUE,
  AlLOWED_USER_TO_PUSH_UP_PRIORITY
} from 'constants/shipment-details';
import { PUDO_PROVIDER, SOHO } from 'constants/shipments';
import { COUNTRIES_CODE_NAME } from 'common/countries/constants/countries';
import { ORDER_CANCELLATION_ACCESS } from 'constants/Deliveries';
import { ASSIGN_LIVE_OPS } from 'constants/live-ops';
import { editOrder } from 'services/shipments';
import { escalateOrDeEscalateTicket } from 'services/support-tickets';
import aclMatrix, { VENDOR_MANAGER } from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import { getSeverityLevel } from 'utils/escalation';

import { notify } from 'components/Notify/Notify';
import ConfirmationModal from 'components/BRModals/ConfirmationModal/ConfirmationModal';

import ReschduleDeliveryOptionModal from 'components/Shipments/components/NewDeliveryDetails/components/ReschduleDeliveryOptionModal/ReschduleDeliveryOptionModal';
import CancelOrderModal from 'components/Shipments/components/NewDeliveryDetails/components/CancelOrderModal/CancelOrderModal';
import ValidateModal from 'components/LiveOps/components/ValidateModal/ValidateModal';
import CallModal from 'components/LiveOps/components/CallModal/CallModal';
import EscalateModal from 'components/Shipments/components/NewDeliveryDetails/components/EscalateModal/EscalateModal';
import EscalationSeverity from 'components/Escalation/components/TicketsAging/components/EscalationSeverity/EscalationSeverity';

import { ReactComponent as ShowBusinessViewIcon } from 'assets/imgRevamp/ShowBusinessViewIcon.svg';
import { ReactComponent as PrintAirWayBillIcon } from 'assets/imgRevamp/NewPrintAirWayBillIcon.svg';
import { ReactComponent as ReschduleIcon } from 'assets/imgRevamp/DeliveryActionRescduleIcon.svg';
import { ReactComponent as Validate } from 'assets/imgRevamp/tick-square.svg';
import { ReactComponent as Call } from 'assets/imgRevamp/call-calling.svg';
import { ReactComponent as RateStar } from 'assets/imgRevamp/rate-star.svg';
import { ReactComponent as Printer } from 'assets/imgRevamp/printer.svg';
import { ReactComponent as Cancel } from 'assets/imgRevamp/close-square.svg';
import { ReactComponent as Escalate } from 'assets/imgRevamp/EscalationFlag.svg';

import './OrderInfoHeader.less';

const OrderInfoHeader = ({
  setEscalatedTicket,
  stateColorGroupClassName,
  escalatedTicket,
  deliveryTickets,
  stateName,
  delivery,
  history,
  setIsLoading,
  onSuccess,
  proofOfReturnData,
  intl
}) => {
  const [isSendingLoading, setIsSendingLoading] = useState(false);
  const handleOnRescheduleClick = () => {
    if (
      COMPLETED_ORDERS_REACHES_FINAL_STATES_CODES.includes(
        delivery?.state?.code
      )
    ) {
      notify('This order has reached a final state. You can’t reschedule it.');
    } else if (
      !COMPLETED_ORDERS_REACHES_FINAL_STATES_CODES.includes(
        delivery?.state?.code
      ) &&
      !ORDER_EDITABLE_STATES_CODE.includes(delivery?.state?.code)
    ) {
      notify(
        'You can only reschedule an order if its state is received at warehouse or in transit.'
      );
    } else if (ORDER_EDITABLE_STATES_CODE.includes(delivery?.state?.code)) {
      openModal(ReschduleDeliveryOptionModal, {
        delivery: delivery,
        onSuccess: onSuccess
      });
    }
  };

  const handleOnClickValidate = (id) =>
    openModal(ValidateModal, { id, onSuccess: onSuccess });

  const handleOnClickCall = (id) =>
    openModal(CallModal, {
      id,
      onSuccess: onSuccess
    });
  // delivery._id
  const lockService = async (id, type) => {
    const payload = {
      updateType: ASSIGN_LIVE_OPS,
      liveOpsActionType: type
    };

    try {
      await lockUnlockDeliveryItem(id, payload);
      if (type === 'call') {
        handleOnClickCall(id);
      } else {
        handleOnClickValidate(id);
      }
    } catch (err) {
      notify(err.message);
    }
  };

  const printProofOfReturn = () => {
    if (proofOfReturnData?.returnContactPersonName) {
      notify(
        intl.formatMessage({
          id: 'deliveries.delivery_details.proof_of_return.por_is_otp'
        })
      );
    } else if (proofOfReturnData) {
      showBill(proofOfReturnData);
    } else {
      notify(
        intl.formatMessage({
          id: 'deliveries.delivery_details.proof_of_return.error_msg'
        })
      );
    }
  };

  const handleCancelClick = () => {
    openModal(CancelOrderModal, { delivery: delivery, onSuccess: onSuccess });
  };
  const handleEscalationClick = () => {
    openModal(EscalateModal, {
      delivery: delivery,
      deliveryTickets: deliveryTickets,
      onSuccess: onSuccess
    });
  };
  const handleCancelEscalation = async () => {
    const payload = {
      removeEscalation: true
    };
    try {
      await escalateOrDeEscalateTicket(escalatedTicket._id, payload);
      notify(
        intl.formatMessage({
          id: 'deliveries.delivery_details.header.cancel_escalation_success'
        }),
        'success'
      );
      setEscalatedTicket(null);
    } catch (e) {
      notify(e.message);
    }
  };
  const handleCancelEscalationClick = () => {
    openModal(ConfirmationModal, {
      title: intl.formatMessage({
        id: 'deliveries.delivery_details.cancel_escalation_modal.title'
      }),
      content: intl.formatMessage({
        id: 'deliveries.delivery_details.cancel_escalation_modal.content'
      }),
      confirmButtonLabel: intl.formatMessage({
        id: 'deliveries.delivery_details.cancel_escalation_modal.confirm'
      }),
      cancelButtonLabel: intl.formatMessage({
        id: 'deliveries.delivery_details.cancel_escalation_modal.cancel'
      }),
      modalAction: handleCancelEscalation
    });
  };

  const actionsMenuItems = [
    {
      icon: ReschduleIcon,
      title: intl.formatMessage({
        id: 'deliveries.delivery_details.header.reschedule'
      }),
      action: handleOnRescheduleClick
    },
    {
      icon: Escalate,
      title: intl.formatMessage({
        id: escalatedTicket
          ? 'deliveries.delivery_details.header.cancel_escalation'
          : 'deliveries.delivery_details.header.escluate_order'
      }),
      disabled: !deliveryTickets.length,
      action: escalatedTicket
        ? handleCancelEscalationClick
        : handleEscalationClick,
      access: isUserAuthorized(
        aclMatrix.DELIVERIES,
        [ACL_MATRIX.TICKETS_ESCALATE],
        {
          countries: [COUNTRIES_CODE_NAME.EG]
        }
      )
    },
    {
      icon: Cancel,
      title: intl.formatMessage({
        id: 'deliveries.delivery_details.header.cancel'
      }),
      action: handleCancelClick,
      className: 'br-delivery-details__cancel-action',
      access: isUserAuthorized(ORDER_CANCELLATION_ACCESS)
    },
    {
      icon: Call,
      title: intl.formatMessage({
        id: 'live_ops.table.actions.call'
      }),
      action: () => lockService(delivery._id, 'call')
    },
    {
      icon: Validate,
      title: intl.formatMessage({
        id: 'live_ops.table.actions.validate'
      }),
      action: () => lockService(delivery._id, 'validate')
    },
    {
      icon: RateStar,
      title: intl.formatMessage({
        id: 'deliveries.delivery_details.header.rate_star'
      }),
      action: () => handleOpenRateStarModal(delivery, onSuccess)
    },
    {
      icon: Printer,
      title: intl.formatMessage({
        id: 'deliveries.delivery_details.header.print_por'
      }),
      action: printProofOfReturn
    }
  ];
  const actionsMenu = (
    <Menu>
      {actionsMenuItems.map(
        (
          { icon, title, action, access = true, className = '', disabled },
          index
        ) =>
          access && (
            <Menu.Item onClick={action} key={index} disabled={disabled}>
              <div
                className={`d-flex br-live-ops__actions-menus__item ${className}`}
              >
                <Icon component={icon} />
                <span className="br-order-info-header__reschedule-title">
                  {title}
                </span>
              </div>
            </Menu.Item>
          )
      )}
    </Menu>
  );

  const handlePrintAWP = async () => {
    setIsLoading(true);
    if (
      COMPLETED_ORDERS_REACHES_FINAL_STATES_CODES.includes(
        delivery?.state?.code
      )
    ) {
      notify(
        intl.formatMessage({
          id: 'deliveries.delivery_details.header.print_awb_final_state'
        })
      );
    } else {
      try {
        const { data } = (await printAirWayBill(delivery._id)) || {};
        if (data) {
          downloadAsPdf(data, delivery.trackingNumber);
        }
      } catch (err) {
        notify(err.message);
      }
    }
    setIsLoading(false);
  };

  const handlePushPriorityUp = async () => {
    if (delivery?.state?.value !== EXCEPTION) {
      notify(
        `The order is ${delivery?.state?.value}, you can only push the priority of the exception up live ops exception list if its state is Exception.`
      );
      return;
    }
    try {
      setIsSendingLoading(true);
      await editOrder({ id: delivery._id, updateType: 'pushUpException' });
      notify(
        'Push up exception for this delivery has been updated successfully',
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
    setIsSendingLoading(false);
  };

  return (
    <div>
      <div className="br-order-info-header__options-container">
        <div className="br-order-info-header__tn-state-name-container">
          <span className="br-order-info-header__tn">
            TN : {delivery?.trackingNumber}
          </span>
          {delivery?.isFulfillmentOrder && (
            <span className="br-order-info-header__fxf">
              {intl.formatMessage({
                id: 'fulfillment.fulfillment_flag'
              })}
            </span>
          )}

          {delivery?.integrationInfo?.provider === PUDO_PROVIDER && (
            <span className="br-order-info-header__fxf">PUDO</span>
          )}

          <span
            className={classnames(
              'br-order-info-header__state',
              stateColorGroupClassName
            )}
          >
            {stateName}
          </span>
          {delivery?.ccod?.POSDelivery && (
            <span className="br-order-info-header__ccod-tag">
              {intl.formatMessage({
                id: 'deliveries.delivery_details.header.ccod'
              })}
            </span>
          )}
          <span>
            {delivery?.creationSrc?.includes(SOHO) && (
              <Tag className="br-order-info__soho-tag">{SOHO}</Tag>
            )}
          </span>
          {escalatedTicket && (
            <div className="br-order-info__escalation-level-container">
              {
                <EscalationSeverity
                  severityLevel={getSeverityLevel(
                    escalatedTicket?.escalationInfo?.severityDate
                  )}
                />
              }
            </div>
          )}
        </div>
        <div className="br-order-info-header__options">
          {isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) && (
            <Dropdown
              overlay={actionsMenu}
              trigger="click"
              overlayClassName="br-order-info-header__dropdown-menu"
            >
              <Button>
                {intl.formatMessage({
                  id: 'deliveries.delivery_details.header.more_actions'
                })}{' '}
                <CaretDownOutlined />
              </Button>
            </Dropdown>
          )}

          {isUserAuthorized(AlLOWED_USER_TO_PUSH_UP_PRIORITY) && (
            <Button
              className="br-order-info-header__options-business-view-button"
              onClick={handlePushPriorityUp}
              disabled={isSendingLoading}
            >
              <RiseOutlined />
              Push Priority Up
            </Button>
          )}
          {isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) ? (
            <Link
              className="br-order-info-header__options-business-view-button"
              to={{
                pathname: `/deliveries/${delivery?._id}/business-view-details`
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ShowBusinessViewIcon />
              {intl.formatMessage({
                id: 'deliveries.delivery_details.header.business_view'
              })}
            </Link>
          ) : null}
          <Button
            className="br-order-info-header__options-print-awb-button"
            onClick={handlePrintAWP}
          >
            <PrintAirWayBillIcon />
            {intl.formatMessage({
              id: 'deliveries.delivery_details.header.print_awb'
            })}
          </Button>
        </div>
      </div>
      <div className="br-order-info-header__order-info-section">
        <div className="br-order-info-header__FM-creation-date-section">
          <span className="br-order-info-header__order-info-title">
            {![
              ORDER_TYPES_VALUE.CASH_COLLECTION,
              ORDER_TYPES_VALUE.CRP
            ].includes(delivery?.type)
              ? 'FM Pickup Date'
              : 'Creation Date'}
          </span>
          <span className="br-order-info-header__order-info-value br-order-info__date-value">
            {![
              ORDER_TYPES_VALUE.CASH_COLLECTION,
              ORDER_TYPES_VALUE.CRP
            ].includes(delivery?.type)
              ? delivery?.collectedFromBusiness
                ? formatDate(delivery?.collectedFromBusiness)
                : 'N/A'
              : delivery?.createdAt
              ? formatDate(delivery?.createdAt)
              : 'N/A'}
          </span>
        </div>
        {isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) && (
          <>
            <div className="br-order-info-header__business-name-section">
              <span className="br-order-info-header__order-info-title">
                Business Name
              </span>
              <span className="br-order-info-header__order-info-value">
                <a
                  href={`/businesses/${delivery?.sender?._id}/details`}
                  target="_blank"
                >
                  {delivery?.sender?.name}
                </a>
              </span>
            </div>
            {delivery?.sender?.subAccountId && (
              <div className="br-order-info-header__business-name-section">
                <span className="br-order-info-header__order-info-title">
                  Sub-Account Name
                </span>
                <span className="br-order-info-header__order-info-value">
                  <a
                    href={`/businesses/${delivery?.sender?.subAccountId}/details`}
                    target="_blank"
                  >
                    {delivery?.sender?.subAccountName}
                  </a>
                </span>
              </div>
            )}
          </>
        )}

        <div className="br-order-info-header__sla-section">
          <span className="br-order-info-header__order-info-title">SLA</span>
          <span className="br-order-info-header__order-info-value">
            {delivery?.sla?.e2eSla?.e2eSlaTimestamp
              ? formatSla(delivery)
              : 'N/A'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default withRouter(injectIntl(OrderInfoHeader));
