import { useState } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import { getReturnToVendorStatistics } from 'services/fulfillment-return-to-vendor';
import {
  FULFILLMENT_RETURN_TO_VENDOR_MAIN_PATH,
  RETURN_TO_VENDOR_STATE_IDS
} from 'constants/fulfillment-return-to-vendor';

import POStatistics from 'components/NewFulfillment/FulfillmentInbound/components/POList/components/POStatistics/POStatistics';
import POFilters from 'components/NewFulfillment/FulfillmentInbound/components/POList/components/POFilters/POFilters';
import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import DateFilter from 'components/NewFulfillment/FulfillmentInbound/components/POList/components/DateFilter/DateFilter';
import ReturnToVendorTable from 'components/NewFulfillment/FulfillmentReturnToVendor/components/ReturnToVendorList/components/ReturnToVendorTable/ReturnToVendorTable';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as CompletedIcon } from 'assets/bosta-icons/CompletedIcon.svg';
import { ReactComponent as PlusIcon } from 'assets/bosta-icons/Plus.svg';

const ReturnToVendorList = ({ intl, history }) => {
  const [tableFilters, setTableFilters] = useState({
    state: null
  });
  const [isFirstFetchDone, setIsFirstFetchDone] = useState(false);
  const [statisticsFilters, setStatisticsFilters] = useState({});

  const { COMPLETED } = RETURN_TO_VENDOR_STATE_IDS;

  const getStatisticsListToRender = ({ total, completedCount }) => [
    {
      key: 'total',
      title: intl.formatMessage({
        id: 'fulfillment_returns.statistics.titles.total'
      }),
      content: total,
      stateIdFilterValue: null
    },
    {
      key: 'completed',
      title: intl.formatMessage({
        id: 'fulfillment_problematic_jobs.state_name.completed'
      }),
      content: completedCount,
      icon: <CompletedIcon />,
      stateIdFilterValue: COMPLETED
    }
  ];

  const updateFilters = (filter) => {
    if (isFirstFetchDone) {
      setTableFilters((prev) => ({
        ...prev,
        ...filter
      }));
    }
  };

  const updateStatisticsFilters = (filter) => {
    if (isFirstFetchDone) {
      setStatisticsFilters((prev) => ({
        ...prev,
        ...filter
      }));
    }
  };

  return (
    <Container
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'fulfillment_return_to_vendor.title'
          })}
        />
      }
      content={
        <>
          <div className="br-fulfillment-return-list__date-filter-section">
            <DateFilter
              updateFilters={updateFilters}
              updateStatisticsFilters={updateStatisticsFilters}
            />
          </div>
          <POStatistics
            updateFilters={updateFilters}
            poFilters={tableFilters}
            fetchData={getReturnToVendorStatistics}
            getStatisticsListToRender={getStatisticsListToRender}
            statisticsFilters={statisticsFilters}
          />
          <POFilters updateFilters={updateFilters} hideDateFilter />
          <div className="br-create-new-po-btn">
            <BRButton
              type="primary"
              label={intl.formatMessage({
                id: 'fulfillment_return_to_vendor.create_new_rtv_btn'
              })}
              prefixIcon={<PlusIcon />}
              onClick={() =>
                history.push(`${FULFILLMENT_RETURN_TO_VENDOR_MAIN_PATH}/create`)
              }
            />
          </div>
          <ReturnToVendorTable
            tableFilters={tableFilters}
            isFirstFetchDone={isFirstFetchDone}
            setIsFirstFetchDone={setIsFirstFetchDone}
          />
        </>
      }
    />
  );
};

export default injectIntl(withRouter(ReturnToVendorList));
