import { fmt } from 'IntlWrapper/IntlWrapper';
import { SORTING_HUB } from './hubs';

export const RESCHEDULED = 'RESCHEDULED';
export const UPDATED_DELIVERY_DETAILS = 'UPDATED_DELIVERY_DETAILS';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const TRANSFER_TO_SORTING = 'TRANSFER_TO_SORTING';
export const ADDRESS_UPDATED = 'ADDRESS_UPDATED';
export const RESCHEDULED_TOMORROW = 'RESCHEDULED_TOMORROW';
export const RETURN_ISSUE_RESOLVED = 'RETURN_ISSUE_RESOLVED';
export const STAR_INSTRUCTION = 'STAR_INSTRUCTION';
export const CHANGE_COD = 'CHANGE_COD';
export const CHANGE_DELIVERY_DETAILS = 'CHANGE_DELIVERY_DETAILS';
export const RESTORED_DELIVERY = 'RESTORED_DELIVERY'

export const UPDATES_COLORS = {
  RESCHEDULED: 'br-state-yellow-group',
  UPDATED_DELIVERY_DETAILS: 'br-state-blue-group',
  TRANSFER_TO_SORTING: 'br-state-blue-group',
  CANCEL_ORDER: 'br-state-red-group',
  RETURN_ISSUE_RESOLVED: 'br-state-blue-group',
  STAR_INSTRUCTION: 'br-state-yellow-group',
  CHANGE_COD: 'br-state-green-group'
};

export const UPDATES_TO_STRING = {
  RESCHEDULED: fmt({
    id: `orders.order_new_statues.rescheduled`
  }),
  UPDATED_DELIVERY_DETAILS: fmt({
    id: 'action_center.update_order_details'
  }),
  TRANSFER_TO_SORTING: fmt({
    id: 'action_center.update_order_details'
  }),
  CANCEL_ORDER: fmt({
    id: 'action_center.cancelled'
  }),
  RETURN_ISSUE_RESOLVED: fmt({ id: 'action_center.return_issue_resolved' }),
  STAR_INSTRUCTION: fmt({ id: 'action_center.delivery_insctructions' }),
  CHANGE_COD: fmt({ id: 'action_center.change_cod' })
};

export const UPDATES_TO_ACTION = (hubType) => ({
  RESCHEDULED: fmt({ id: 'action_center.order_actions.move_to_bin' }),
  UPDATED_DELIVERY_DETAILS: fmt({
    id: 'action_center.order_details.action_notes.print_awb'
  }),
  TRANSFER_TO_SORTING:
    hubType !== SORTING_HUB
      ? fmt({
          id: 'action_center.order_details.action_notes.action_note_update_transfer_to_sorting'
        })
      : fmt({
          id: 'action_center.transfer_to_hub'
        }),
  CANCEL_ORDER: fmt({
    id: 'action_center.order_actions.move_to_bin_add_rto_stricker'
  }),
  RETURN_ISSUE_RESOLVED: fmt({ id: 'action_center.order_actions.move_to_bin' }),
  CHANGE_COD: fmt({
    id: 'action_center.order_details.action_notes.print_awb'
  })
});

export const ACTION_CENTER_PAGE_SIZE = 12;

export const ACTION_NOTES = {
  RESCHEDULED: fmt({
    id: 'action_center.order_details.action_notes.action_note_rescheduled'
  }),
  UPDATED_DELIVERY_DETAILS: fmt({
    id: 'action_center.order_details.action_notes.print_awb'
  }),
  TRANSFER_TO_SORTING: fmt({
    id: 'action_center.order_details.action_notes.action_note_update_transfer_to_sorting'
  }),
  CANCEL_ORDER: (
    <>
      <div>
        {fmt({
          id: 'action_center.order_details.action_notes.add_rto_sticker'
        })}
      </div>
      <div>
        {fmt({
          id: 'action_center.order_details.action_notes.move_to_rto_bin'
        })}
      </div>
    </>
  ),
  RETURN_ISSUE_RESOLVED: fmt({
    id: 'action_center.order_details.action_notes.move_to_order_to_dispatch'
  }),
  CHANGE_COD: fmt({
    id: 'action_center.order_details.action_notes.print_awb'
  })
};

export const ACTION_CENTER_ALL_HUBS_ACCESS = [
  'SUPER_ADMIN',
  'OPERATIONS_MANAGER',
  'AREA_MANAGER',
  'VENDOR_MANAGER'
];

export const EDIT_OFD_ALL_HUBS_ACCESS = [
  ...ACTION_CENTER_ALL_HUBS_ACCESS,
  'OPERATOR',
  'EXPERIENCE'
];

export const OFD_EDITS_ORDER_STATES = {
  22: fmt({
    id: 'deliveries.states.out_for_delivery'
  }),
  41: fmt({
    id: 'deliveries.states.out_for_delivery'
  }),
  47: fmt({
    id: 'deliveries.states.exception'
  })
};

export const AUDIT_ACTION_REPORT_TYPE = 'actionsSummary';

export const CHANGE_TO_RTO_UPDATE_TYPE = 'changeToRTO';
export const WHATSAPP_LINK = 'https://wa.me/';
export const ACTION_CENTER_TABS = {
  ACTION_CENTER: 'ACTION_CENTER',
  REQUESTS: 'REQUESTS'
};

export const ACTION_CENTER_TABS_INDEX = {
  1: ACTION_CENTER_TABS.ACTION_CENTER,
  2: ACTION_CENTER_TABS.REQUESTS
};

export const REQUESTS_TYPE = {
  CHANGE_COD: 'CHANGE_COD'
};

export const REQUESTS_TITLES = {
  CHANGE_COD: fmt({ id: 'action_center.requests.types.change_cod' })
};

export const REQUEST_UPDATE_TYPE = {
  APPROVE: 'approve',
  REJECT: 'reject'
};

export const REJECTION_REASONS = [
  {
    label: fmt({
      id: 'action_center.requests.reject_request_modal.reasons.already_deliveried'
    }),
    value: 'The order is already delivered.'
  },
  {
    label: fmt({
      id: 'action_center.requests.reject_request_modal.reasons.couldnt_reach_courier'
    }),
    value: 'We couldn’t reach the courier.'
  }
];
