import { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Tooltip,
  Radio,
  Divider,
  Select,
  Checkbox,
  Row,
  Col,
  InputNumber
} from 'antd';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

import {
  COMPENSATION_DEPARTMENTS_MAPPER,
  COMPENSATION_DEPARTMENTS_NAMES,
  COMPENSATION_INVESTIGATION_REASONS,
  SIZES_SORT_WEIGHT,
  LIABILITY_VALUES
} from 'constants/wallet';
import { conatinUrlRule, postiveCompensationAmountRule } from 'forms';
import { isSaudi } from 'constants/helper';
import { checkOrderExistance, getHubs } from 'services/hubs';
import {
  getComepnsationReasons,
  getDepartmentsApprovals
} from 'services/compensation';
import { getPricingSizes } from 'services/pricing';
import { getBusiness } from 'services/business';

import PriceBreakdownToolTip from 'components/AddCompensationFormModal/components/PriceBreakdownToolTip/PriceBreakdownToolTip';
import BRFormInputNumber from 'components/BRFormInputNumber/BRFormInputNumber';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as SelectCustomIcon } from 'assets/imgRevamp/select-custom-icon.svg';

import './CompensationInfoSection.less';

const CompensationInfoSection = ({
  intl,
  delivery,
  onSubmit,
  formRef,
  setTotalAmount,
  totalAmount,
  setCompensationReason,
  setAssignedDepartment,
  setFreeOrdersTotalAmount,
  setWrongPackageSizeAmounts
}) => {
  const APPROVED_BY_LARGE_AMOUNT = (amount) => [
    {
      label: intl.formatMessage({
        id: 'request_compensation_modal.md'
      }),
      errorMessage: intl.formatMessage({
        id: 'request_compensation_modal.validation_messages.md_error'
      })
    },
    amount >= 10000
      ? {
          label: intl.formatMessage({
            id: 'request_compensation_modal.cfo'
          }),
          errorMessage: intl.formatMessage({
            id: 'request_compensation_modal.validation_messages.cfo_error'
          })
        }
      : {}
  ];

  const [showSingleMultipleOrders, setShowSingleMultipleOrders] =
    useState(false);
  const [disableOrderIdInput, setDisableOrderIdInput] = useState(true);
  const [ordersArray, setOrdersArray] = useState([delivery?.trackingNumber]);
  const [disableCompensationType, setDisableCompensationType] = useState({
    free: false,
    credit: false
  });
  const [showItemCountInfo, setShowItemCountInfo] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [showDepartmentSection, setShowDepartmentSection] = useState(false);
  const [disableNoOfOrders, setDisableNoOfOrders] = useState(true);
  const [showInvalidError, setShowInvalidError] = useState(null);
  const [orderIdConfirmed, setOrderIdConfirmed] = useState(false);
  const [selectedDeliveries, setSelectedDeliveries] = useState([]);
  const [defaultDelivery, setDefaultDelivery] = useState([]);
  const [compesationReasons, setCompensationReasons] = useState([]);
  const [departmentApprovals, setDepartmentApprovals] = useState([]);
  const [pricingSizes, setPricingSizes] = useState([]);
  const [showCompensationLiability, setShowCompensationLiability] =
    useState(false);
  const [showApprovedByLargeAmount, setShowApprovedByLargeAmount] =
    useState(false);
  const [approvedByLargeAmountError, setApprovedByLargeAmountError] = useState({
    MD: false,
    CFO: false
  });
  const [showFormItemHelp, setShowFormItemHelp] = useState({
    ticketUrl: true,
    orderId: true
  });

  const [hubs, setHubs] = useState([]);

  const [form] = Form.useForm();
  const deparments = Form.useWatch('assignedDepartment', form) || [];
  const liabilityArray = Form.useWatch('liabilityOn', form) || [];

  const listHubs = async () => {
    try {
      const { result } = await getHubs();
      const mappedHubs = result.map((hub) => ({
        key: hub._id,
        label: hub.name,
        value: hub.name
      }));
      setHubs(mappedHubs);
    } catch (e) {
      notify(e.message);
    }
  };

  useEffect(() => {
    getCompensationInfo();
    listHubs();
  }, []);

  useEffect(() => {
    if (totalAmount >= 5000) {
      if (!showApprovedByLargeAmount) {
        setShowApprovedByLargeAmount(true);
      }
    } else {
      if (showApprovedByLargeAmount) {
        setShowApprovedByLargeAmount(false);
      }
    }
  }, [totalAmount]);

  const getCompensationInfo = async () => {
    try {
      const [
        { data: compensationReasons },
        { data: departmentApprovals },
        deliveryDetails,
        {
          pricingTier: { _id }
        }
      ] = await Promise.all([
        getComepnsationReasons(),
        getDepartmentsApprovals(),
        checkOrderExistance({
          ids: delivery.trackingNumber
        }),
        getBusiness(delivery.sender._id)
      ]);
      const {
        data: { sizes }
      } = await getPricingSizes(_id);
      setPricingSizes(
        sizes.sort(
          (a, b) => SIZES_SORT_WEIGHT[a.name] - SIZES_SORT_WEIGHT[b.name]
        )
      );
      setCompensationReasons(compensationReasons);
      setDepartmentApprovals(departmentApprovals);
      setAssignedDepartment(departmentApprovals);
      setSelectedDeliveries(deliveryDetails);
      setDefaultDelivery(deliveryDetails);
    } catch (error) {
      notify(error.message);
    }
  };

  const onFormChange = async (values) => {
    const {
      compensationReason,
      orderType,
      orderId,
      compensationType,
      assignedDepartment,
      sizeConfirmation,
      noOfFreeOrder,
      ticketUrl,
      compensationAmount
    } = values;

    if (typeof ticketUrl !== 'undefined') {
      const errorList = await formRef.current.getFieldsError();
      if (!ticketUrl || errorList[0].errors?.length || ticketUrl?.length < 5) {
        setShowFormItemHelp({
          ticketUrl: false
        });
      }
    }

    if (typeof compensationReason !== 'undefined') {
      const selectedReason = compesationReasons[compensationReason];
      setSelectedReason(selectedReason);
      setCompensationReason(selectedReason);
      setDisableNoOfOrders(
        selectedReason?.compensationType[0].type === 'Free' &&
          selectedReason?.compensationType[0].hasMoreThanOne
      );
      setShowSingleMultipleOrders(selectedReason?.isMultipleOrderCompensation);

      setDisableCompensationType(
        selectedReason?.compensationType?.length === 1
          ? selectedReason?.compensationType[0].type === 'Free'
            ? {
                free: false,
                credit: true
              }
            : {
                free: true,
                credit: false
              }
          : {
              free: false,
              credit: false
            }
      );
      setShowCompensationLiability(true);

      if (selectedReason?.assignedDepartments?.length === 1) {
        setSelectedDepartments(selectedReason?.assignedDepartments);
        formRef.current.setFieldsValue({
          assignedDepartment: selectedReason.assignedDepartments
        });
      } else {
        await setShowDepartmentSection(false);
        setSelectedDepartments([]);
        formRef.current.setFieldsValue({
          assignedDepartment: []
        });
      }

      !selectedReason?.isMultipleOrderCompensation &&
      selectedReason?.compensationType?.length === 1 &&
      selectedReason?.compensationType[0].type === 'Free'
        ? calculateTotalAmount()
        : setTotalAmount(0);
      formRef.current.setFieldsValue({
        compensationType:
          selectedReason?.compensationType?.length === 2
            ? null
            : selectedReason?.compensationType[0].type,
        ...(!selectedReason.isMultipleOrderCompensation
          ? {
              orderId: delivery?.trackingNumber
            }
          : {})
      });
      setShowDepartmentSection(selectedReason?.compensationType?.length !== 2);
      setShowItemCountInfo(
        selectedReason?.compensationType?.length === 1 &&
          selectedReason?.compensationType[0].type === 'Free'
      );
      setDisableOrderIdInput(true);
      setOrdersArray([delivery]);
      !selectedReason?.isMultipleOrderCompensation &&
        selectedReason?.compensationType?.length === 1 &&
        selectedReason?.compensationType[0].type === 'Free' &&
        selectedDeliveries.forEach((delivery) =>
          formRef.current.setFieldsValue({
            noOfFreeOrder: {
              [delivery?.trackingNumber]: '1'
            }
          })
        );

      if (selectedReason?.isMultipleOrderCompensation) {
        setOrdersArray([delivery?.trackingNumber]);
        setSelectedDeliveries(defaultDelivery);
        setOrderIdConfirmed(false);
        formRef.current.setFieldsValue({
          orderType: 'single',
          noOfFreeOrder: {
            [delivery?.trackingNumber]: '1'
          }
        });
        selectedReason?.compensationType?.length === 1 &&
        selectedReason?.compensationType[0].type === 'Free'
          ? calculateTotalAmount(0, defaultDelivery)
          : setTotalAmount(0);
      } else {
        setOrdersArray([defaultDelivery[0]?.trackingNumber]);
        setSelectedDeliveries(defaultDelivery);
        selectedReason?.compensationType[0].type === 'Free'
          ? calculateTotalAmount(0, defaultDelivery)
          : setTotalAmount(0);
      }
    }
    if (orderType) {
      if (orderType === 'single') {
        formRef.current.setFieldsValue({
          orderId: delivery?.trackingNumber
        });
        setShowInvalidError('');
        setOrderIdConfirmed(false);
      }
      setShowCompensationLiability(
        orderType === 'single' ? true : orderIdConfirmed
      );
      setDisableOrderIdInput(orderType === 'single' ? true : false);
      setOrdersArray([delivery?.trackingNumber]);
      setSelectedDeliveries(defaultDelivery);
      orderType === 'multiple'
        ? setTotalAmount(0)
        : calculateTotalAmount(0, defaultDelivery);
    }
    if (typeof orderId !== 'undefined') {
      setShowInvalidError(null);
      if (orderId === '') {
        setOrdersArray([]);
        setShowFormItemHelp({ ...showFormItemHelp, orderId: false });
      } else {
        const set = Array.from(
          new Set(orderId?.split(',').map((id) => id.trim()))
        );
        setOrdersArray(set);
        setShowFormItemHelp({ ...showFormItemHelp, orderId: true });
      }
    }
    if (compensationType) {
      setShowItemCountInfo(compensationType === 'Free');
      compensationType === 'Credit'
        ? setTotalAmount(0)
        : calculateTotalAmount(0, true);

      compensationType === 'Free' &&
        selectedDeliveries.forEach((delivery) =>
          formRef.current.setFieldsValue({
            noOfFreeOrder: {
              [delivery?.trackingNumber]: '1'
            }
          })
        );
      if (!showDepartmentSection) {
        setShowDepartmentSection(true);
      }
    }
    if (assignedDepartment) {
      setSelectedDepartments(assignedDepartment);
    }
    if (typeof sizeConfirmation !== 'undefined') {
      let compensationAmount = 0;
      const sizeObject = JSON.parse(sizeConfirmation);
      const vat = isSaudi() ? 1.15 : 1.14;
      selectedDeliveries.forEach((delivery) => {
        let transitCostForSize = delivery.pricing?.transit?.cost ?? 0;
        let currentSizePrice = delivery.pricing?.size?.cost ?? 0;
        let sizeMultiplierPrice = delivery.pricing?.size?.multiplier ?? 0;
        let amount = 0;
        if (
          delivery.pricing?.size?.name === 'Heavy Bulky' ||
          delivery.pricing?.size?.name === 'Light Bulky'
        ) {
          if (
            sizeObject?.name === 'Light Bulky' &&
            delivery.pricing?.size?.name === 'Heavy Bulky'
          ) {
            let lightBulkySize = pricingSizes.filter(
              (size) => size.name === 'Light Bulky'
            )[0];

            amount =
              currentSizePrice -
              transitCostForSize +
              transitCostForSize * sizeMultiplierPrice -
              (lightBulkySize?.cost -
                transitCostForSize +
                transitCostForSize * lightBulkySize?.multiplier);
          } else {
            if (sizeObject?.name === 'Light Bulky') {
              amount = 0;
            } else {
              amount =
                currentSizePrice -
                transitCostForSize +
                transitCostForSize * sizeMultiplierPrice -
                sizeObject.cost;
            }
          }
        } else {
          amount = currentSizePrice - sizeObject?.cost;
        }
        setWrongPackageSizeAmounts((prev) => {
          return {
            ...prev,
            [delivery.trackingNumber]: Math.round(amount * vat * 10) / 10 ?? 0
          };
        });
        compensationAmount += amount;
      });

      setTotalAmount(Math.round(compensationAmount * vat * 10) / 10 ?? 0);
      formRef.current.setFieldsValue({
        compensationAmount: {
          OPs: Math.round(compensationAmount * vat * 10) / 10 || '0'
        }
      });
    }
    if (noOfFreeOrder) {
      calculateTotalAmount();
    }
    if (compensationAmount) {
      calculateTotalAmount(1);
    }
  };

  const validateMultipleOrder = async () => {
    try {
      if (ordersArray.length > 50) {
        setShowInvalidError(
          intl.formatMessage({
            id: 'request_compensation_modal.validation_messages.more_than_50_id'
          })
        );
        return;
      }
      const numberRegex = /^\d+$/;
      let exitFunction = false;
      ordersArray.forEach((orderid) => {
        orderid = orderid.trim();
        if (!numberRegex.test(orderid)) {
          setShowInvalidError(
            intl.formatMessage({
              id: 'request_compensation_modal.validation_messages.invalid_input_id'
            })
          );
          exitFunction = true;
          return;
        }
      });
      if (exitFunction) return;
      let result = await checkOrderExistance({
        ids: ordersArray.join(',')
      });
      if (ordersArray.length !== result?.length) {
        setShowInvalidError(
          intl.formatMessage({
            id: 'request_compensation_modal.validation_messages.invalid_tracking_numbers'
          })
        );
      } else {
        result.forEach((d) => {
          if (d?.sender?._id !== delivery?.sender._id) {
            setShowInvalidError(
              intl.formatMessage({
                id: 'request_compensation_modal.validation_messages.tracking_numbers_dont_belong_to_business'
              })
            );
            result = [];
            return;
          }
        });
        if (result?.length) {
          setSelectedDeliveries(result);
          calculateTotalAmount();
          setShowCompensationLiability(true);
          setOrderIdConfirmed(true);
          formRef.current.getFieldValue('compensationType') === 'Free' &&
            calculateTotalAmount(0, result);
          formRef.current.getFieldValue('compensationType') === 'Free' &&
            result.forEach((delivery) =>
              formRef.current.setFieldsValue({
                noOfFreeOrder: {
                  [delivery?.trackingNumber]: '1'
                }
              })
            );
        }
      }
    } catch (error) {
      notify(error.message);
    }
  };

  const handleOnCompensationAmountChange = (department, value) => {
    formRef.current.setFieldsValue({
      compensationAmount: { [department]: value }
    });
    calculateTotalAmount(1);
  };

  const calculateTotalAmount = (type = 0, deliveries = []) => {
    const orderMultiples = formRef.current.getFieldValue('noOfFreeOrder');
    const compensationAmount =
      formRef.current.getFieldValue('compensationAmount');
    const loopDeliveries = deliveries?.length ? deliveries : selectedDeliveries;
    let newTotalAmount = 0;
    if (type === 0) {
      let eachOrderTotalAmount = {};
      loopDeliveries?.forEach((delivery) => {
        newTotalAmount +=
          delivery?.pricing?.priceAfterVat *
          parseInt(orderMultiples?.[delivery?.trackingNumber] || 1);
        eachOrderTotalAmount[delivery?.trackingNumber] =
          delivery?.pricing?.priceAfterVat *
          parseInt(orderMultiples?.[delivery?.trackingNumber] || 1);
      });
      setTotalAmount(newTotalAmount);
      setFreeOrdersTotalAmount(eachOrderTotalAmount);
    } else {
      Object.values(compensationAmount).forEach(
        (amount) => amount && (newTotalAmount += parseInt(amount) ?? 0)
      );
      setTotalAmount(newTotalAmount);
    }
  };

  const handleUnCheckingDepartmentsThenRemoveItAmount = (values) => {
    const compensationAmount =
      formRef.current.getFieldValue('compensationAmount');
    const approvedBy = formRef.current.getFieldValue('approvedBy');
    const investigationReason = formRef.current.getFieldValue(
      'investigationReason'
    );
    if (compensationAmount && Object.keys(compensationAmount).length) {
      Object.keys(compensationAmount).forEach((department) => {
        if (!values.includes(department)) {
          compensationAmount[department] = null;
          if (
            approvedBy &&
            Object.keys(approvedBy).length &&
            Object.keys(approvedBy).includes(department)
          ) {
            approvedBy[department] = null;
          }
        }
      });

      formRef.current.setFieldsValue({
        compensationAmount,
        approvedBy,
        investigationReason
      });
      calculateTotalAmount(1);
    }
  };

  return (
    <div className="br-compensation-info-content-container">
      <Form
        form={form}
        layout="vertical"
        ref={formRef}
        scrollToFirstError
        onFinish={onSubmit}
        onValuesChange={onFormChange}
        initialValues={{ orderId: delivery?.trackingNumber }}
        name="compensationInfoForm"
      >
        {/* compensation details section */}
        <Divider>
          {intl.formatMessage({
            id: 'request_compensation_modal.compensation_details_title'
          })}
        </Divider>
        <Form.Item
          label={intl.formatMessage({
            id: 'request_compensation_modal.form_title.ticket_url'
          })}
          extra={
            showFormItemHelp.ticketUrl &&
            intl.formatMessage({
              id: 'request_compensation_modal.form_title.ticket_url_subtitle'
            })
          }
          name="ticketUrl"
          validateFirst
          hasFeedback
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'request_compensation_modal.validation_messages.ticket_url_required'
              })
            },
            conatinUrlRule(
              intl.formatMessage({
                id: 'request_compensation_modal.validation_messages.invalid_url'
              })
            )
          ]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'request_compensation_modal.form_placeholders.ticket_url'
            })}
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'request_compensation_modal.form_title.compensation_reason'
          })}
          name="compensationReason"
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'request_compensation_modal.validation_messages.compensation_reason'
              })
            }
          ]}
        >
          <Select
            placeholder={intl.formatMessage({
              id: 'request_compensation_modal.form_placeholders.compensation_reason'
            })}
            suffixIcon={<SelectCustomIcon />}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {compesationReasons?.map((reason, key) => (
              <Select.Option key={reason?._id} value={key}>
                {reason?.reason}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {showSingleMultipleOrders && (
          <Form.Item
            className="br-compensation__order-type-radio"
            name="orderType"
          >
            <Radio.Group defaultValue="single">
              <Radio value="single">
                {intl.formatMessage({
                  id: 'request_compensation_modal.form_title.single_order'
                })}
              </Radio>
              <Radio value="multiple">
                {intl.formatMessage({
                  id: 'request_compensation_modal.form_title.multiple_orders'
                })}
              </Radio>
            </Radio.Group>
          </Form.Item>
        )}
        <Form.Item
          label={intl.formatMessage({
            id: `request_compensation_modal.form_title.${
              disableOrderIdInput ? 'order_id' : 'multiple_order_title'
            }`
          })}
          extra={
            !disableOrderIdInput
              ? showFormItemHelp.orderId &&
                intl.formatMessage({
                  id: 'request_compensation_modal.form_title.multiple_orders_subtitle'
                })
              : ''
          }
          name="orderId"
          validateFirst
          validateStatus={showInvalidError ? 'error' : null}
          help={showInvalidError}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'request_compensation_modal.validation_messages.order_ids'
              })
            }
            // {
            //   validator(rule, value, callback, source, options) {
            //     const errors = [];
            //     return validateMultipleOrder();
            //   }
            // }
          ]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: `request_compensation_modal.form_placeholders.${
                disableOrderIdInput ? 'order_id' : 'multiple_order_id'
              }`
            })}
            disabled={disableOrderIdInput || orderIdConfirmed}
            suffix={
              !disableOrderIdInput && (
                <span
                  className="br-compensation-info__confrim-order-id-button"
                  onClick={!orderIdConfirmed ? validateMultipleOrder : () => {}}
                >
                  {intl.formatMessage({
                    id: 'common.confirm'
                  })}
                </span>
              )
            }
          />
        </Form.Item>

        {/* compensation liability section */}
        {showCompensationLiability && (
          <>
            <Divider>
              {intl.formatMessage({
                id: 'request_compensation_modal.compensation_liability_title'
              })}
            </Divider>
            <Form.Item
              className="br-compensation__order-type-radio"
              name="compensationType"
              label={intl.formatMessage({
                id: 'request_compensation_modal.form_title.compensation_type_title'
              })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'request_compensation_modal.validation_messages.compensation_type'
                  })
                }
              ]}
            >
              <Radio.Group>
                <Radio value="Free" disabled={disableCompensationType.free}>
                  {intl.formatMessage({
                    id: 'request_compensation_modal.form_title.free_orders_title'
                  })}
                </Radio>
                <Radio value="Credit" disabled={disableCompensationType.credit}>
                  {intl.formatMessage({
                    id: 'request_compensation_modal.form_title.credit_title'
                  })}
                </Radio>
              </Radio.Group>
            </Form.Item>
            {showItemCountInfo && (
              <div className="br-compensation-form__no-of-items-container">
                {selectedDeliveries.map(
                  (order, index) =>
                    order &&
                    order?.trackingNumber?.length > 5 && (
                      <div
                        className="br-compensation-form__no-of-items-content"
                        key={order?.trackingNumber}
                      >
                        <span className="br-compensation-form__order-id-text">
                          #{order?.trackingNumber}
                        </span>
                        <Form.Item
                          className="br-compensation__order-type-radio"
                          name={['noOfFreeOrder', order?.trackingNumber]}
                          rules={[
                            {
                              required: true,
                              message: 'Please select a number'
                            }
                          ]}
                        >
                          <InputNumber
                            min="1"
                            max="10"
                            disabled={!disableNoOfOrders}
                            className="br-compensation__input-number-field"
                          />
                        </Form.Item>
                        <span className="br-compensation-info__x-text">X</span>
                        <Tooltip
                          title={
                            <PriceBreakdownToolTip
                              pricingInfo={order?.pricing}
                            />
                          }
                          overlayClassName="br-compensation-info-tooltip-container"
                        >
                          <span className="br-compensation-info__price-text">
                            {order?.pricing?.currency}{' '}
                            {Math.round(order?.pricing?.priceAfterVat * 100) /
                              100 ?? 0}
                          </span>
                        </Tooltip>
                      </div>
                    )
                )}
              </div>
            )}
            <Divider />
            {selectedReason?.reason === 'Wrong Package Size' && (
              <Form.Item
                name="sizeConfirmation"
                label={intl.formatMessage({
                  id: 'request_compensation_modal.form_title.size_confirmation'
                })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'request_compensation_modal.validation_messages.size_confirmation'
                    })
                  }
                ]}
              >
                <Select
                  placeholder={intl.formatMessage({
                    id: 'request_compensation_modal.form_placeholders.size_confirmation'
                  })}
                  suffixIcon={<SelectCustomIcon />}
                >
                  {pricingSizes.map(
                    (size) =>
                      !['Bulky', 'Heavy Bulky'].includes(size?.name) && (
                        <Select.Option
                          key={size?._id}
                          value={JSON.stringify({
                            cost: size?.cost,
                            name: size?.name
                          })}
                        >
                          {size?.alias === 'XXL'
                            ? `${size?.alias} (${size?.name})`
                            : size?.name}
                        </Select.Option>
                      )
                  )}
                </Select>
              </Form.Item>
            )}
            {showDepartmentSection && (
              <Form.Item
                name="assignedDepartment"
                className="br-compensation__order-assigned-department-check-container"
                label={intl.formatMessage({
                  id: 'request_compensation_modal.form_title.assigned_departments'
                })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'request_compensation_modal.validation_messages.department'
                    })
                  }
                ]}
              >
                <Checkbox.Group
                  defaultValue={['OPs']}
                  onChange={handleUnCheckingDepartmentsThenRemoveItAmount}
                >
                  {Array.from(
                    {
                      length: Math.ceil(
                        selectedReason?.assignedDepartments.length / 2
                      )
                    },
                    (_, i) => 2 * i + 1
                  ).map((item) => (
                    <Row>
                      {selectedReason?.assignedDepartments?.map(
                        (department, key) =>
                          key <= item &&
                          key > item - 2 && (
                            <Col
                              span={
                                selectedReason?.assignedDepartments?.length > 1
                                  ? 12
                                  : 24
                              }
                            >
                              <Checkbox key={key} value={department?.name}>
                                {COMPENSATION_DEPARTMENTS_MAPPER[
                                  department?.name
                                ] || department?.name}
                              </Checkbox>
                              {selectedDepartments.includes(
                                department?.name
                              ) && (
                                <>
                                  <Form.Item
                                    name={['approvedBy', department?.name]}
                                    className={classnames(
                                      'br-compensation__order-assigned-department-check-info',
                                      {
                                        'br-compensation__order-full-row':
                                          selectedReason?.assignedDepartments
                                            .length <= 1
                                      }
                                    )}
                                    rules={[
                                      {
                                        required: true,
                                        message: intl.formatMessage({
                                          id: 'request_compensation_modal.validation_messages.approved_by'
                                        })
                                      }
                                    ]}
                                  >
                                    <Select
                                      placeholder={intl.formatMessage({
                                        id: 'request_compensation_modal.form_placeholders.approved_by'
                                      })}
                                      suffixIcon={<SelectCustomIcon />}
                                      showSearch
                                    >
                                      {departmentApprovals
                                        .filter(
                                          (departments) =>
                                            departments.name ===
                                            department?.name
                                        )[0]
                                        ?.approvals?.map((person) => (
                                          <Select.Option
                                            key={person?.name}
                                            data-value={person}
                                          >
                                            {person?.name}
                                          </Select.Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                  {department?.name ===
                                    COMPENSATION_DEPARTMENTS_NAMES.INVESTIGATION && (
                                    <Form.Item
                                      name="investigationReason"
                                      className={classnames(
                                        'br-compensation__order-assigned-department-check-info',
                                        {
                                          'br-compensation__order-full-row':
                                            selectedReason?.assignedDepartments
                                              .length <= 1
                                        }
                                      )}
                                      rules={[
                                        {
                                          required: true,
                                          message: intl.formatMessage({
                                            id: 'request_compensation_modal.validation_messages.investigation_reason'
                                          })
                                        }
                                      ]}
                                    >
                                      <Select
                                        placeholder={intl.formatMessage({
                                          id: 'request_compensation_modal.form_placeholders.investigation_reason'
                                        })}
                                        suffixIcon={<SelectCustomIcon />}
                                        showSearch
                                        mode="single"
                                      >
                                        {COMPENSATION_INVESTIGATION_REASONS.map(
                                          (reason, id) => (
                                            <Select.Option
                                              key={id}
                                              data-value={reason}
                                              value={reason}
                                            >
                                              {reason}
                                            </Select.Option>
                                          )
                                        )}
                                      </Select>
                                    </Form.Item>
                                  )}
                                  {!!department?.liabilities?.length && (
                                    <Form.Item
                                      name="liabilityOn"
                                      className={classnames(
                                        'br-compensation__order-assigned-department-check-info',
                                        {
                                          'br-compensation__order-full-row':
                                            selectedReason?.assignedDepartments
                                              .length <= 1
                                        }
                                      )}
                                      rules={[
                                        {
                                          required: true,
                                          message: intl.formatMessage({
                                            id: 'request_compensation_modal.validation_messages.approved_by'
                                          })
                                        }
                                      ]}
                                    >
                                      <Select
                                        placeholder={'Liability'}
                                        suffixIcon={<SelectCustomIcon />}
                                        showSearch
                                        mode="multiple"
                                      >
                                        {department?.liabilities.map(
                                          (liability, id) => (
                                            <Select.Option
                                              key={id}
                                              data-value={liability}
                                              value={liability}
                                            >
                                              {liability}
                                            </Select.Option>
                                          )
                                        )}
                                      </Select>
                                    </Form.Item>
                                  )}
                                  {!showItemCountInfo && (
                                    <Form.Item
                                      name={[
                                        'compensationAmount',
                                        department?.name
                                      ]}
                                      className={classnames(
                                        'br-compensation__order-assigned-department-check-info',
                                        {
                                          'br-compensation__order-full-row':
                                            selectedReason?.assignedDepartments
                                              .length <= 1
                                        }
                                      )}
                                      hasFeedback
                                      rules={[
                                        {
                                          required: true,
                                          message: intl.formatMessage({
                                            id: 'request_compensation_modal.validation_messages.compensation_amount'
                                          })
                                        },
                                        postiveCompensationAmountRule(
                                          intl.formatMessage({
                                            id: 'request_compensation_modal.validation_messages.amount_negative'
                                          })
                                        )
                                      ]}
                                    >
                                      <BRFormInputNumber
                                        addonBefore={isSaudi() ? 'SAR' : 'EGP'}
                                        placeholder={intl.formatMessage({
                                          id: 'request_compensation_modal.form_placeholders.compensation_amount'
                                        })}
                                        filedName={department?.name}
                                        handleOnChangeForm={
                                          handleOnCompensationAmountChange
                                        }
                                        disabled={
                                          selectedReason?.reason ===
                                          'Wrong Package Size'
                                        }
                                      />
                                    </Form.Item>
                                  )}
                                  {deparments.includes(
                                    COMPENSATION_DEPARTMENTS_MAPPER.OPs
                                  ) &&
                                    liabilityArray.includes(
                                      LIABILITY_VALUES.HUB
                                    ) && (
                                      <Form.Item
                                        name="hubName"
                                        className="br-compensation__order-assigned-department-check-info"
                                        rules={[
                                          {
                                            required: true,
                                            message: intl.formatMessage({
                                              id: 'request_compensation_modal.validation_messages.hub'
                                            })
                                          }
                                        ]}
                                      >
                                        <Select
                                          placeholder={intl.formatMessage({
                                            id: 'request_compensation_modal.hub'
                                          })}
                                          suffixIcon={<SelectCustomIcon />}
                                          showSearch
                                          mode="single"
                                          options={hubs}
                                        />
                                      </Form.Item>
                                    )}
                                </>
                              )}
                            </Col>
                          )
                      )}
                    </Row>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            )}
            {showApprovedByLargeAmount && (
              <Form.Item
                name="approvedByLargeAmount"
                className="br-compensation__order-assigned-department-check-container br-compensation__approved_cfo_md"
                label={intl.formatMessage({
                  id: 'request_compensation_modal.form_title.approved_by'
                })}
                rules={[
                  {
                    required: true,
                    message: 'Please choose options'
                  },
                  {
                    validator(rule, value, callback, source, options) {
                      const optionsLength =
                        totalAmount >= 5000
                          ? totalAmount >= 10000
                            ? 2
                            : 1
                          : 0;
                      if (!value?.length) {
                        setApprovedByLargeAmountError({
                          CFO: true,
                          MD: true
                        });
                        return Promise.reject('Please select this options');
                      } else if (value?.length !== optionsLength) {
                        setApprovedByLargeAmountError({
                          MD: true,
                          CFO: true,
                          [value[0]]: false
                        });
                        return Promise.reject('1');
                      } else {
                        setApprovedByLargeAmountError({
                          MD: false,
                          CFO: false
                        });
                        return Promise.resolve();
                      }
                    }
                  }
                ]}
              >
                <Checkbox.Group>
                  <Row>
                    {APPROVED_BY_LARGE_AMOUNT(totalAmount)?.map(
                      (position, key) =>
                        position?.label && (
                          <Col span={12}>
                            <Checkbox key={key} value={position.label}>
                              <div>
                                {position.label}
                                {approvedByLargeAmountError[position.label] && (
                                  <p>{position.errorMessage}</p>
                                )}
                              </div>
                            </Checkbox>
                          </Col>
                        )
                    )}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            )}
          </>
        )}
      </Form>
    </div>
  );
};

export default injectIntl(CompensationInfoSection);
