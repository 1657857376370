import http from 'utils/http';

export const debriefDeliveriesOfStar = (starId, params) => {
  return http.post(`/admin/deliveries/${starId}/debrief`, null, { params });
};

export const deliveriesHasBulky = (businessID) => {
  return http.get(`deliveries/has-bulky?businessId=${businessID}`);
};

export const exportXlsx = async (data) => {
  const res = await http.post(`/deliveries/exportxlsx?`, data);
  return res;
};

export const starActionLog = (trackingNumber) => {
  return http.get(`/admin/deliveries/${trackingNumber}/star-actions`);
};

export const rateStar = (id, payload) => {
  return http.post(`/admin/deliveries/rate/${id}`, payload);
};

export const searchDeliveries = (payload) => {
  return http.post(`/deliveries/search`, payload);
};

export const searchDeliveriesFilter = (payload) => {
  return http.post(`/flyers/deliveries/search`, payload);
};

export const setDeliveriesOnShelf = (payload) => {
  return http.put(`/admin/deliveries/setShelfLocation`, payload);
};

export const getAllRejectedReturnDeliveries = (payload) => {
  return http.get(`/admin/deliveries/rejected-returns`, payload);
};

export const updateManyDeliveries = (payload) => {
  return http.put(`/admin/deliveries/updateMany`, payload);
};

export const fetchDeliveriesCount = (payload) => {
  return http.post('/deliveries/count', payload);
};

export const updateDelivery = (deliveryId, payload) => {
  return http.put(`/admin/deliveries/${deliveryId}`, payload);
};

export const forceHubAssignment = (deliveryId, payload) => {
  return http.post(`/admin/deliveries/${deliveryId}/force-hub`, payload);
};
