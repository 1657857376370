import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import { Formik } from 'formik';
import { Button, Form, Col, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { debounce, get as getValue } from 'lodash';
import { Table, Tag } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import { UPDATED_DELIVERY_DETAILS } from 'constants/action-center';
import { DELIVERIES_SCAN_TYPES, FIRST_MILE_HUB } from 'constants/hubs';
import { createCSV } from '../../../utils';
import { openModal } from 'utils/modal';
import { scanDeliveries, getSortingFacility } from 'services/hubs';
import { beep, getHubs, getVerifiedStars, warning } from '../../../actions';
import { createWarehouseTransfer } from '../../../actions/HubsActions';
import { validateMultiPackageLastScan } from 'utils/hubs';

import deliveryStateFormatter from 'containers/Deliveries/common/DeliveryStateFormatter';
import { notify } from 'components/Notify/Notify';
import TransferToHubErrorModal from './components/TransferToHubErrorModal/TransferToHubErrorModal';
import ConfirmationModal from 'components/BRModals/ConfirmationModal/ConfirmationModal';
import MultiPackagesCount from 'components/MultiPackages/components/MultiPackagesCount/MultiPackagesCount';
import MissingMultiPackagesModal from 'components/MultiPackages/components/MissingMultiPackagesModal/MissingMultiPackagesModal';

import { isDefaultBostaCourier } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';

import styles from '../styles.module.scss';
import './TransferToHub.less';

/**
 * format options to be bound in select controls
 * @param keyFieldName
 * @param labelFieldName
 * @param data
 * @returns {*}
 */
const formatOptions = (keyFieldName, labelFieldName, data) => {
  if (keyFieldName && labelFieldName) {
    const labelFields = labelFieldName.split(',');
    return data.map((item) => {
      const labelValue = labelFields.reduce((acc, value) => {
        if (!value) return acc;
        return `${acc} ${getValue(item, value, '')}`;
      }, '');

      return { value: item[keyFieldName], label: labelValue };
    });
  }
  return data.map((item) => ({ value: item, label: item }));
};
const userInfo = JSON.parse(localStorage.getItem('userInfo'));

const TransferSelectionForm = ({
  hubs,
  stars,
  setSelectedStar,
  setSelectedHub,
  // trackingNumber,
  handleConfirmTransferList,
  addToScannedItem,
  setSealNumbers,
  hubFromParam,
  selectedDestinationHub,
  setSelectedDestinationHub,
  selectedOriginHub,
  sortingFacility,
  scannedItems
}) => {
  const inputRef = useRef(null);
  const formRef = useRef(null);
  useEffect(() => {
    if (formRef && hubFromParam) {
      formRef.current.setFieldValue('hub', hubFromParam);
      setSelectedHub(hubFromParam);
    }
  }, [hubFromParam]);

  const handleConfirmTransferListClick = (resetForm) => {
    if (scannedItems.some((item) => item.multiPackages !== item.scannedTimes)) {
      return openModal(MissingMultiPackagesModal, {
        deliveries: scannedItems,
        onConfirm: () => handleConfirmTransferList(resetForm)
      });
    }

    openModal(ConfirmationModal, {
      title: 'Confirm Transfer',
      content: `Are you sure you want to Transfer ${scannedItems?.length} deliveries to ${selectedDestinationHub?.label}?`,
      confirmButtonLabel: 'Confirm',
      modalAction: () => handleConfirmTransferList(resetForm)
    });
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={() => {}}
      handleChage
      initialValues={{
        hub: !isDefaultBostaCourier(['WAREHOUSE_CLERK', 'HUB_LEADER'])
          ? [
              {
                value: userInfo?.warehouseInfo?._id,
                label: userInfo?.warehouseInfo?.name
              }
            ]
          : '',
        star: '',
        trackingNumber: '',
        sealNumbers: ''
      }}
      ref={formRef}
      render={({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        // initialValues,
        setFieldValue,
        errors,
        resetForm
        // ...rest
      }) => (
        <Form
          className={styles.moneyDebrief}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(values);
          }}
        >
          <Form.Row className={styles.formRow}>
            <Form.Group as={Col} sm={6} controlId="hub-control">
              <Form.Label className={styles.formLabel}>Hub</Form.Label>
              <Select
                name="hub"
                onBlur={handleBlur}
                value={values.hub}
                options={formatOptions('_id', 'name', hubs)}
                isInvalid={!!errors.hub && !!touched.hub}
                onChange={(e) => {
                  setFieldValue('hub', e);
                  setSelectedHub(e);
                }}
                isDisabled={
                  !isDefaultBostaCourier(['WAREHOUSE_CLERK', 'HUB_LEADER'])
                }
              />
              {errors.hub && touched.hub ? (
                <Form.Control.Feedback type="invalid">
                  {errors.hub}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group as={Col} sm={6} controlId="destination-hub-control">
              <Form.Label className={styles.formLabel}>
                Destination Hub
              </Form.Label>
              <Select
                name="hub"
                value={selectedDestinationHub}
                isDisabled={!selectedOriginHub}
                options={formatOptions(
                  '_id',
                  'name',
                  !isDefaultBostaCourier([
                    'VENDOR_MANAGER',
                    'HUB_LEADER',
                    'WAREHOUSE_CLERK'
                  ])
                    ? sortingFacility
                    : hubs.filter(
                        (el) =>
                          selectedOriginHub &&
                          el._id !== selectedOriginHub.value
                      )
                )}
                onChange={(e) => {
                  setSelectedDestinationHub(e);
                }}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row className={styles.formRow}>
            <Form.Group as={Col} sm={6} controlId="trackingNumber-control">
              <Form.Label className={styles.formLabel}>
                Scan/Enter Tracking No
              </Form.Label>
              <Form.Control
                ref={inputRef}
                type="text"
                name="trackingNumber"
                // value={trackingNumber}
                onChange={(evt) => {
                  addToScannedItem(evt.currentTarget.value.trim(), inputRef);
                }}
              />
            </Form.Group>

            <Form.Group as={Col} sm={6} controlId="sealNumbers-control">
              <Form.Label className={styles.formLabel}>Seal Numbers</Form.Label>
              <Form.Control
                type="text"
                name="trackingNumber"
                value={values.sealNumbers}
                onChange={(evt) => {
                  setFieldValue('sealNumbers', evt.currentTarget.value.trim());
                  setSealNumbers(evt.currentTarget.value.trim());
                }}
              />
            </Form.Group>
            <Form.Group as={Col} sm={6} controlId="star-control">
              <Form.Label className={styles.formLabel}>Star</Form.Label>
              <Select
                options={formatOptions(
                  '_id',
                  'profile.firstName,profile.lastName',
                  stars
                )}
                onBlur={handleBlur}
                value={values.star}
                isInvalid={!!errors.stars && !!touched.star}
                onChange={(e) => {
                  setFieldValue('star', e);
                  setSelectedStar(e);
                }}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row className={`${styles.formRow} justify-content-end`}>
            <Form.Group as={Col} sm={4}>
              <Button
                type="button"
                className="primaryButton"
                disabled={
                  !values.hub ||
                  !selectedDestinationHub ||
                  !scannedItems?.length
                }
                onClick={() => handleConfirmTransferListClick(resetForm)}
              >
                Confirm Transfer List
              </Button>
            </Form.Group>
            <Form.Group as={Col} sm={4}>
              <Button type="reset" className="primaryButton">
                Clear
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
      )}
    />
  );
};

/**
 * format datasource with actions
 * @param data
 * @param remove
 * @returns {*}
 */
const formatDatasource = (data, { remove }) =>
  data.map((item) => {
    const {
      _id,
      trackingNumber,
      sender,
      state,
      type,
      cod,
      externalScanned,
      multiPackages,
      scannedTimes
    } = item;

    if (externalScanned) {
      return {
        _id,
        key: _id,
        trackingNumber,
        actions: (
          <DeleteOutlined
            title="Delete"
            style={{ color: '#d7262c' }}
            onClick={() => remove(_id)}
          />
        )
      };
    }
    return {
      _id,
      key: _id,
      trackingNumber,
      noOfPackages: multiPackages ? <MultiPackagesCount delivery={item} /> : 1,
      business: sender.name,
      state: deliveryStateFormatter.getStateName(item),
      type,
      hub: getValue(state, 'receivedAtWarehouse.warehouse.name'),
      cod: cod?.amount > 0 && <Tag color="green">COD</Tag>,
      multiPackages,
      scannedTimes,
      actions: (
        <DeleteOutlined
          title="Delete"
          style={{ color: '#d7262c' }}
          onClick={() => remove(_id)}
        />
      )
    };
  });

const TransferToHub = ({
  hubs,
  fetchHubs,
  stars,
  fetchStars,
  playBeep,
  location: { state: stateFromProps }
  // playWarning,
}) => {
  const [loading, setLoading] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState(null);
  const [scannedItems, setScannedItems] = useState([]);
  const [selectedStar, setSelectedStar] = useState(null);
  const [selectedOriginHub, setSelectedOriginHub] = useState(
    !isDefaultBostaCourier(['WAREHOUSE_CLERK', 'HUB_LEADER'])
      ? {
          value: userInfo?.warehouseInfo?._id,
          label: userInfo?.warehouseInfo?.name
        }
      : null
  );
  const [selectedDestinationHub, setSelectedDestinationHub] = useState(null);
  const [sealNumbers, setSealNumbers] = useState(null);
  const [sortingFacility, setSortingFacility] = useState([]);
  // const inputRef = useRef(null);

  useEffect(() => {
    if (stateFromProps) {
      const { deliveries, destinationHubId } = stateFromProps;
      if (deliveries && destinationHubId) {
        setScannedItems(deliveries);
      }
    }
  }, [stateFromProps]);

  useEffect(() => {
    if (
      !isDefaultBostaCourier([
        'VENDOR_MANAGER',
        'HUB_LEADER',
        'WAREHOUSE_CLERK'
      ])
    ) {
      getSortingFacilityHubs();
    }
  }, []);

  useEffect(() => {
    const { destinationHubId } = stateFromProps || {};
    if (hubs?.length && destinationHubId) {
      const destinationHub = {
        label: hubs.find(({ _id }) => _id === destinationHubId)?.name,
        value: destinationHubId
      };
      setSelectedDestinationHub(destinationHub);
    }
  }, [hubs]);

  useEffect(() => {
    fetchHubs();
    fetchStars();
  }, [fetchHubs, fetchStars]);

  const getSortingFacilityHubs = async () => {
    try {
      const { warehouses } = await getSortingFacility();
      setSortingFacility(warehouses);
    } catch (error) {
      notify(error.message);
    }
  };

  const hasPendingOrderUpdateActions = (delivery) => {
    return !!delivery?.actionItems.filter(
      (item) =>
        item.action === UPDATED_DELIVERY_DETAILS &&
        !item.isApplied &&
        item?.actionDetails?.dropOffAddress
    ).length;
  };

  const checkPackageValidity = ({
    item,
    originHub,
    inputRef,
    selectedDestinationHub
  }) => {
    console.log('checking');
    if (!originHub) {
      notify(`Please select hub first.`);
      return false;
    }
    if (!selectedDestinationHub) {
      notify('Please select destination hub first.');
      return false;
    }
    if (item?.externalScanned) {
      return true;
    }
    if (item.warehouseTransit) {
      notify(
        `The delivery with tracking number ${item.trackingNumber}, is already in transit.`,
        'error',
        true
      );
      return false;
    }
    if (
      getValue(item, 'state.receivedAtWarehouse.warehouse._id') !==
      originHub.value
    ) {
      notify(
        `The delivery with tracking number ${item.trackingNumber} is not located in the specified origin warehouse.`,
        'error',
        true
      );
      return false;
    }
    if (hasPendingOrderUpdateActions(item)) {
      openModal(TransferToHubErrorModal, {
        delivery: item,
        onSuccess: () =>
          changeSearchFor(
            item.trackingNumber,
            originHub,
            inputRef,
            selectedDestinationHub
          )
      });
      return false;
    }

    return true;
  };

  const changeSearchFor = async (
    trackingNo,
    originHub,
    ref,
    selectedDestinationHub,
    foundIndex
  ) => {
    setLoading(true);

    const payload = {
      searchType: DELIVERIES_SCAN_TYPES.TRANSFER_TO_HUB_SCAN,
      trackingNumbers: [trackingNo]
    };

    try {
      const { data } = await scanDeliveries(payload);
      if (
        data[0] &&
        checkPackageValidity({
          item: data[0],
          originHub,
          inputRef: ref,
          selectedDestinationHub
        })
      ) {
        if (foundIndex > -1) {
          const newScannedItems = [...scannedItems];
          newScannedItems[foundIndex].scannedTimes += 1;

          setScannedItems(newScannedItems);
        } else {
          setScannedItems((prevState) => {
            return [
              ...prevState,
              {
                ...data[0],
                ...(data[0].multiPackages && {
                  scannedTimes: 1
                })
              }
            ];
          });
        }

        playBeep();
        ref.current.value = '';
      }
    } catch (error) {
      notify(error.message);
    }

    setLoading(false);
    setTrackingNumber('');
    ref.current.focus();
  };

  const addToScannedItem = debounce((trackingNo, ref) => {
    setTrackingNumber(trackingNo);
    if (trackingNo.length >= 6) {
      const foundIndex = scannedItems.findIndex(
        (el) => el.trackingNumber === trackingNo
      );
      if (
        foundIndex > -1 &&
        validateMultiPackageLastScan(scannedItems[foundIndex])
      ) {
        notify('Added before in the list of packages', 'error', true);
        setTrackingNumber('');
        return;
      }

      changeSearchFor(
        trackingNo,
        selectedOriginHub,
        ref,
        selectedDestinationHub,
        foundIndex
      );
    }
  }, 500);

  const removeFromScannedItem = (id) => {
    const item = scannedItems.find((el) => el._id === id);
    if (item) {
      setScannedItems((prevState) => {
        const newState = prevState.filter((el) => el._id !== id);
        return [...newState];
      });
    }
  };
  const requiredParams =
    (fn, condition) =>
    (...args) => {
      if (condition && !condition()) {
        return;
      }
      fn(...args);
    };
  const transfersConditions = () => {
    if (!selectedOriginHub) {
      notify('Please select origin hub!');
      return false;
    }
    if (!selectedStar) {
      notify('Please select star!');
      return false;
    }
    if (!sealNumbers) {
      notify('Please enter seal number!');
      return false;
    }
    return true;
  };

  const isFirstMileHub = (hubId) => {
    return hubs?.find(
      (hub) => hub._id === hubId && hub.type === FIRST_MILE_HUB
    );
  };

  const scannedForwardOrdersWithCod = (orders) => {
    return orders?.filter(
      (order) =>
        !order.changedToRTODate &&
        !order.collectedFromConsignee &&
        order?.cod?.amount > 0
    )?.length;
  };

  const handleConfirmTransferList = requiredParams(async (resetForm) => {
    if (
      isFirstMileHub(selectedDestinationHub.value) &&
      scannedForwardOrdersWithCod(scannedItems)
    ) {
      return notify(
        'You scanned some forward orders with COD amounts, please remove them before transferring to the FM & Reverse hub'
      );
    }

    const payload = {
      originWarehouseId: selectedOriginHub.value,
      destinationWarehouseId: selectedDestinationHub?.value,
      sealNumbers: [sealNumbers],
      starId: selectedStar.value,
      deliveriesToBeTransferredIds: scannedItems.map((el) => el._id)
    };
    setLoading(true);
    const warehouse = await createWarehouseTransfer(payload);
    if (warehouse) {
      createCSV(
        scannedItems.map((el) => {
          if (el?.externalScanned) {
            return {
              trackingNumber: el.trackingNumber
            };
          }
          return {
            trackingNumber: el.trackingNumber,
            sender: el.sender.name,
            hubName: getValue(
              el,
              'state.receivedAtWarehouse.warehouse.name',
              'NA'
            )
          };
        }),
        {
          trackingNumber: 'Tracking Number',
          sender: 'Business',
          hubName: 'From Hub'
        },
        `hub-transfer to ${selectedDestinationHub.label}`
      );
      resetForm();
      setScannedItems([]);
      setTrackingNumber('');
      setSealNumbers('');
      setSelectedStar(null);
      setSelectedDestinationHub(null);

      if (isDefaultBostaCourier(['WAREHOUSE_CLERK', 'HUB_LEADER'])) {
        setSelectedOriginHub(null);
      }
    }
    setLoading(false);
  }, transfersConditions);

  return (
    <div className="br-hub-transfer__container">
      <h3>Choose the hub and the start to begin the debrief</h3>
      <TransferSelectionForm
        className={`${loading ? 'dimmed' : ''}`}
        hubs={hubs}
        stars={stars.data}
        setSelectedStar={setSelectedStar}
        setSelectedHub={setSelectedOriginHub}
        setSealNumbers={setSealNumbers}
        addToScannedItem={addToScannedItem}
        trackingNumber={trackingNumber}
        handleConfirmTransferList={handleConfirmTransferList}
        hubFromParam={stateFromProps?.hub}
        selectedDestinationHub={selectedDestinationHub}
        setSelectedDestinationHub={setSelectedDestinationHub}
        selectedOriginHub={selectedOriginHub}
        sortingFacility={sortingFacility}
        scannedItems={scannedItems}
        // ref={inputRef}
      />
      {/* <div className={styles.hubInputGroup}>
        <InputGroup className={styles.inputSearch}>
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">Search</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl aria-describedby="basic-addon1"/>
        </InputGroup>
      </div> */}
      <div className="mb-4">
        <b>Total Packages:</b>
        <span className="ml-2">{scannedItems.length}</span>
      </div>
      <Table
        // rowSelection={rowSelection}
        className="mt-3"
        rowClassName={(record) =>
          record?.multiPackages && record.multiPackages !== record.scannedTimes
            ? 'br-hub-transfer-table__missing-scans'
            : ''
        }
        style={{ overflow: 'auto' }}
        columns={[
          { dataIndex: 'trackingNumber', title: 'Tracking Number' },
          { dataIndex: 'noOfPackages', title: 'No. of Packages' },
          { dataIndex: 'business', title: 'Business' },
          { dataIndex: 'state', title: 'State' },
          { dataIndex: 'type', title: 'Type' },
          { dataIndex: 'hub', title: 'Hub' },
          { dataIndex: 'cod', title: 'COD' },
          { dataIndex: 'actions', title: 'Actions', align: 'center' }
        ]}
        dataSource={formatDatasource(scannedItems, {
          remove: removeFromScannedItem
        })}
        pagination={false}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchHubs: () => dispatch(getHubs()),
  fetchStars: (data) => dispatch(getVerifiedStars(data)),
  playBeep: () => dispatch(beep()),
  playWarning: () => dispatch(warning())
});
const mapStateToProps = ({ hubs }) => {
  return {
    ...hubs
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TransferToHub));
