const readPDFFileFromByteArray = (data) => {
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], {
    type: 'application/pdf;base64'
  });
  const fileURL = URL.createObjectURL(file);
  return fileURL;
};

export const openPrintRunSheet = (data) => {
  const fileURL = readPDFFileFromByteArray(data);
  const newWindow = window.open('/_loading');
  newWindow.onload = () => {
    newWindow.location = fileURL;
  };
};

export const downloadAsPdf = (data, fileName = '') => {
  const pom = document.createElement('a');
  const fileURL = readPDFFileFromByteArray(data);
  pom.href = fileURL;
  pom.setAttribute('download', fileName);
  pom.click();
};

export const downloadAsXlsx = (text, fileName) => {
  const pom = document.createElement('a');
  const xlsxContent = text; // here we load our csv data
  const blob = new Blob([xlsxContent], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
  });
  const url = URL.createObjectURL(blob);
  pom.href = url;
  pom.setAttribute('download', fileName);
  pom.click();
};

export const downloadAsZip = (fileData, fileName = 'download') => {
  const pom = document.createElement('a');
  const blob = new Blob([fileData], {
    type: 'application/zip'
  });
  const url = URL.createObjectURL(blob);
  pom.href = url;
  pom.setAttribute('download', fileName);
  pom.click();
};

export const downloadHeader = {
  responseType: 'arraybuffer',
  headers: {
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  }
};
