import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Dropdown, Menu, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';

import { exportPOs, getPOs } from 'services/fulfillment';
import { downloadAsXlsx } from 'utils';
import {
  FULFILLMENT_INBOUND_PO_MAIN_PATH,
  PO_STATE_IDS,
  PO_ID_KEY,
  PO_KEY,
  SERIAL_ID_KEY,
  SKU_KEY
} from 'constants/fulfillment';
import {
  getSerialForScan,
  getSkuForScan
} from 'constants/fulfillment-sales-orders';
import { getFulfillmentInboundCutOffTime } from 'common/countries/countries-mapping';
import { ORDER_SORT } from 'constants/helper';

import {
  renderPOName,
  getPORenderedTag,
  confirmOnUserBeforeCancelPO,
  formatDateTime,
  handlePrintLabels,
  TABLE_PAGE_SIZE_OPTIONS
} from 'components/NewFulfillment/FulfillmentInbound/components/ViewPO/constants';
import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as ActionsIcon } from 'assets/bosta-icons/More-Actions.svg';
import { ReactComponent as EditIcon } from 'assets/bosta-icons/Edit.svg';
import { ReactComponent as CloseIcon } from 'assets/imgRevamp/closeIconThin.svg';
import { ReactComponent as PrinterIcon } from 'assets/bosta-icons/Printer.svg';
import { ReactComponent as PickupsIcon } from 'assets/bosta-icons/Pickups.svg';

import './POTable.less';

const POTable = ({
  intl,
  poFilters,
  isFirstFetchDone,
  setIsFirstFetchDone
}) => {
  const [poCount, setPOCount] = useState(0);
  const [selectedSearchOption, setSelectedSearchOption] = useState('poId');
  const [sharedPayload, setSharedPayload] = useState({});
  const [searchValue, setSearchValue] = useState('');

  const {
    CREATED,
    CONFIRMED,
    QUALITY_CHECKED,
    READY_TO_PUTAWAY,
    PARTIALLY_PUTAWAY
  } = PO_STATE_IDS;

  const refreshTable = useRef();

  const handleEditPO = (id) =>
    window.open(`${FULFILLMENT_INBOUND_PO_MAIN_PATH}/${id}/edit`, '_blank');

  const menuItems = ({ state, id }) => {
    const renderActionItem = ({ className, text, icon = null, onClick }) => (
      <div
        className={classNames('br-action-menu-item', className)}
        onClick={onClick}
      >
        {icon}
        {text}
      </div>
    );
    const editAction = renderActionItem({
      text: intl.formatMessage({
        id: 'common.edit'
      }),
      icon: <EditIcon />,
      onClick: () => handleEditPO(id)
    });
    const cancelAction = renderActionItem({
      className: 'cancel-action',
      text: intl.formatMessage({
        id: 'common.cancel'
      }),
      icon: <CloseIcon />,
      onClick: () => confirmOnUserBeforeCancelPO(id)
    });
    const printLabelsAction = renderActionItem({
      text: intl.formatMessage({
        id: 'fulfillment_inbound.print_PO_labels.actions.confirm'
      }),
      icon: <PrinterIcon />,
      onClick: () => handlePrintLabels({ poId: id })
    });

    const items = {
      [CREATED]: [editAction, cancelAction],
      [CONFIRMED]: [editAction, cancelAction],
      [QUALITY_CHECKED]: [printLabelsAction],
      [READY_TO_PUTAWAY]: [printLabelsAction],
      [PARTIALLY_PUTAWAY]: [printLabelsAction]
    };

    return (
      items[state?.stateId] && (
        <Menu>
          {items[state?.stateId].map((item, index) => (
            <Menu.Item key={index}>{item}</Menu.Item>
          ))}
        </Menu>
      )
    );
  };

  const fulfillmentInboundCutOffTime = getFulfillmentInboundCutOffTime();

  const columns = [
    {
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.columns.reference'
      }),
      render: ({ id, state }) => (
        <div className="br-po-id-column">
          <Link
            target="_blank"
            to={`${FULFILLMENT_INBOUND_PO_MAIN_PATH}/${id}`}
          >
            {renderPOName(id)}
          </Link>
          {state?.stateId === PARTIALLY_PUTAWAY && (
            <Tooltip
              title={intl.formatMessage({
                id: 'fulfillment_inbound.po_table.putaway_tooltip'
              })}
            >
              <Icon component={PickupsIcon} className="in-progress-icon" />
            </Tooltip>
          )}
        </div>
      )
    },
    {
      dataIndex: 'businessName',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.columns.business_name'
      })
    },
    {
      dataIndex: 'skuCount',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.columns.no_of_sku'
      })
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.columns.quantity'
      }),
      render: ({ qualityCheckedQuantity, confirmedQuantity, totalQuantity }) =>
        qualityCheckedQuantity ?? confirmedQuantity ?? totalQuantity
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.columns.created_on'
      }),
      render: ({ createdAt, state }) => {
        const { hours, formattedDate, formattedTime, isToday, isYesterday } =
          formatDateTime(createdAt);

        const isBeforeCutOffTime = hours < fulfillmentInboundCutOffTime;

        return (
          <>
            {state.stateId === CREATED && (isToday || isYesterday) && (
              <Tooltip
                title={intl.formatMessage({
                  id: `fulfillment_inbound.po_table.${
                    isBeforeCutOffTime ? 'before_cutoff' : 'after_cutoff'
                  }`
                })}
              >
                <span
                  className={`cutoff-dot ${
                    isBeforeCutOffTime ? 'before-cutoff' : 'after-cutoff'
                  }`}
                />
              </Tooltip>
            )}
            {formattedDate}, {formattedTime}
          </>
        );
      },
      sorter: true,
      field: 'createdAt'
    },
    {
      dataIndex: 'createdByName',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.columns.created_by'
      }),
      sorter: true
    },
    {
      dataIndex: 'state',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.columns.status'
      }),
      render: getPORenderedTag,
      sorter: true
    },
    {
      render: (record) => {
        const menu = menuItems(record);

        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <ActionsIcon className={menu ? 'clickable' : 'dimmed'} />
          </Dropdown>
        );
      }
    }
  ];

  const searchOptions = [
    {
      label: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.search_options.po'
      }),
      value: 'poId'
    },
    {
      label: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.search_options.sku'
      }),
      value: 'sku'
    },
    {
      label: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.search_options.serial'
      }),
      value: 'serialId'
    },    {
      label: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.search_options.reference_id'
      }),
      value: 'referenceId'
    },
  ];

  const getFormattedSearchText = (searchInputText) => {
    let value = searchInputText?.trim();

    if (value) {
      switch (selectedSearchOption) {
        case PO_ID_KEY:
          value = value.replace(PO_KEY, '');
          break;

        case SKU_KEY:
          value = getSkuForScan(value);
          break;

        case SERIAL_ID_KEY:
          value = getSerialForScan(value);
          break;

        default:
          break;
      }
    }

    return value;
  };

  const handleGetPOs = async ({
    page,
    limit,
    searchInputText,
    sortField,
    sortOrder
  }) => {
    const value = getFormattedSearchText(searchInputText);
    setSearchValue(value);
    const payload = omitBy(
      {
        page,
        limit,
        ...poFilters,
        ...(value && {
          [selectedSearchOption]: value
        }),
        ...(sortField && {
          sort: `${sortOrder === ORDER_SORT.DESCEND ? '-' : ''}${sortField}`
        })
      },
      isNull
    );

    setSharedPayload(payload);
    setIsFirstFetchDone(true);

    try {
      const {
        data: { purchaseOrders = [], count = 0 }
      } = await getPOs(payload);
      setPOCount(count);

      return {
        list: purchaseOrders,
        total: count
      };
    } catch (error) {
      notify(error.message);
    }
  };

  const handleExportPO = async () => {
    try {
      const data = await exportPOs(sharedPayload);
      const fileName = `POs_${dayjs().format('DD_MM_YYYY')}`;
      downloadAsXlsx(data, fileName);
      notify(
        intl.formatMessage({
          id: 'common.file_downloaded_successfully'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = () => refreshMethod({ pageNumber: 1 });
  };

  const updateTable = () => {
    refreshTable.current();
  };

  useEffect(() => {
    if (isFirstFetchDone && refreshTable?.current) {
      updateTable();
    }
  }, [poFilters]);

  return (
    <BRTable
      className="br-fulfillment-table"
      title={intl.formatMessage(
        {
          id: 'fulfillment_inbound.po_table.title'
        },
        {
          count: poCount
        }
      )}
      columns={columns}
      listFunction={handleGetPOs}
      shareMethods={acceptMethods}
      searchPlaceholder={intl.formatMessage({
        id: 'fulfillment_inbound.po_table.search_placeholder'
      })}
      exportListFileFunction={handleExportPO}
      showFilter={false}
      showSearch
      searchOptions={searchOptions}
      selectedSearchOption={selectedSearchOption}
      setSelectedSearchOption={setSelectedSearchOption}
      pageSizeOptions={TABLE_PAGE_SIZE_OPTIONS}
      emptySearchContent={{
        title: intl.formatMessage({ id: 'common.no_results_found' }),
        hideAction: !searchValue
      }}
    />
  );
};

export default injectIntl(POTable);
