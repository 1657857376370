import { useEffect, useState } from 'react';
import { Form, Select } from 'antd';

import { getValidFulfillmentBusinesses } from 'services/fulfillment';
import { notify } from 'components/Notify/Notify';

const SelectBusiness = ({
  label,
  placeholder,
  isLoading,
  setIsLoading = () => {},
  selectedBusiness,
  setSelectedBusiness,
  isBusinessEditDisabled = false,
  businessId,
  setFetchedBusinesses = () => {}
}) => {
  const [businesses, setBusinesses] = useState([]);

  const fetchBusinesses = async () => {
    try {
      setIsLoading(true);
      const list = await getValidFulfillmentBusinesses();
      setBusinesses(list);
      setFetchedBusinesses(list);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const onBusinessChange = (data) => {
    if (data) {
      setSelectedBusiness({ name: data.label, _id: data.value });
    } else {
      setSelectedBusiness(null);
    }
  };

  useEffect(() => {
    fetchBusinesses();
  }, []);

  useEffect(() => {
    if (businesses.length && businessId) {
      setSelectedBusiness(
        businesses.find((business) => business?._id === businessId)
      );
    }
  }, [businesses, businessId]);

  return (
    <Form.Item label={label} className="br-select-business">
      <Select
        showSearch
        labelInValue
        allowClear
        placeholder={!isBusinessEditDisabled && placeholder}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={businesses}
        fieldNames={{ label: 'name', value: '_id' }}
        onChange={onBusinessChange}
        loading={isLoading}
        disabled={isLoading || isBusinessEditDisabled}
        value={
          selectedBusiness && {
            label: selectedBusiness?.name,
            value: selectedBusiness?._id
          }
        }
      />
    </Form.Item>
  );
};

export default SelectBusiness;
