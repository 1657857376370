import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Dropdown, Menu, Tooltip } from 'antd';
import classNames from 'classnames';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';

import {
  exportFulfillmentSalesOrders,
  getFulfillmentSalesOrders
} from 'services/sales-orders';
import { downloadAsXlsx } from 'utils';
import {
  FULFILLMENT_OUTBOUND_SALES_ORDER_MAIN_PATH,
  SALES_ORDERS_SEARCH_KEYS,
  SALES_ORDERS_SEARCH_OPTIONS,
  SALES_ORDER_STATE_IDS
} from 'constants/fulfillment';
import { getFulfillmentInboundCutOffTime } from 'common/countries/countries-mapping';
import {
  SALES_ORDER_TYPES_NAMES,
  getSkuForScan,
  goToSalesOrder,
  handlePrintAirwaybill,
  isSalesOrdersSelectionAllowed
} from 'constants/fulfillment-sales-orders';

import {
  TABLE_PAGE_SIZE_OPTIONS,
  formatDateTime,
  formatPrice,
  getSalesOrderRenderedTag
} from 'components/NewFulfillment/FulfillmentInbound/components/ViewPO/constants';
import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as UnselectCheckboxIcon } from 'assets/bosta-icons/UnselectCheckbox.svg';
import { ReactComponent as ActionsIcon } from 'assets/bosta-icons/More-Actions.svg';
import { ReactComponent as PackIcon } from 'assets/bosta-icons/Orders.svg';
import { ReactComponent as PrinterIcon } from 'assets/bosta-icons/Printer.svg';

import './SalesOrdersTable.less';

const SalesOrdersTable = ({
  intl,
  salesOrderFilters,
  isFirstFetchDone,
  setIsFirstFetchDone
}) => {
  const [salesOrderCount, setSalesOrderCount] = useState(0);
  const [selectedSearchOption, setSelectedSearchOption] = useState(
    SALES_ORDERS_SEARCH_KEYS.SKU
  );
  const [sharedPayload, setSharedPayload] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [selectedTrackingNumbers, setSelectedTrackingNumbers] = useState([]);

  const { CREATED, PICKED, PACKED, TRANSFERRED } = SALES_ORDER_STATE_IDS;

  const refreshTable = useRef();

  const fulfillmentInboundCutOffTime = getFulfillmentInboundCutOffTime();

  const menuItems = ({ state, trackingNumber, id }) => {
    const renderActionItem = ({ className, text, icon = null, onClick }) => (
      <div
        className={classNames('br-action-menu-item', className)}
        onClick={onClick}
      >
        {icon}
        {text}
      </div>
    );

    const packAction = renderActionItem({
      text: intl.formatMessage({
        id: 'fulfillment_outbound.actions.pack'
      }),
      icon: <PackIcon />,
      onClick: () => goToSalesOrder(id)
    });
    const printAWBAction = renderActionItem({
      text: intl.formatMessage({
        id: 'fulfillment_outbound.actions.print_airwaybill'
      }),
      icon: <PrinterIcon />,
      onClick: () =>
        handlePrintAirwaybill({
          list: trackingNumber
        })
    });

    const items = {
      [PICKED]: [packAction],
      [PACKED]: [printAWBAction],
      [TRANSFERRED]: [printAWBAction]
    };

    return (
      items[state?.stateId] && (
        <Menu>
          {items[state?.stateId].map((item, index) => (
            <Menu.Item key={index}>{item}</Menu.Item>
          ))}
        </Menu>
      )
    );
  };

  const columns = [
    {
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.columns.tracking_number'
      }),
      render: ({ id, trackingNumber }) => (
        <Link
          target="_blank"
          to={`${FULFILLMENT_OUTBOUND_SALES_ORDER_MAIN_PATH}/${id}`}
        >
          {trackingNumber}
        </Link>
      )
    },
    {
      dataIndex: 'businessName',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.columns.business_name'
      })
    },
    {
      dataIndex: 'type',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.columns.order_type'
      }),
      render: (type) => SALES_ORDER_TYPES_NAMES[type?.typeId]
    },
    {
      dataIndex: 'skuCount',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.columns.no_of_sku'
      })
    },
    {
      dataIndex: 'totalQuantity',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.columns.quantity'
      })
    },
    {
      dataIndex: 'totalPrice',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.columns.total_price'
      }),
      render: formatPrice
    },
    {
      dataIndex: 'state',
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.columns.status'
      }),
      render: getSalesOrderRenderedTag
    },
    {
      title: intl.formatMessage({
        id: 'fulfillment_inbound.po_table.columns.last_update'
      }),
      render: ({ updatedAt, state }) => {
        const { hours, formattedDate, formattedTime, isToday, isYesterday } =
          formatDateTime(updatedAt);

        const isBeforeCutOffTime = hours < fulfillmentInboundCutOffTime;

        return (
          <>
            {state.stateId === CREATED && (isToday || isYesterday) && (
              <Tooltip
                title={intl.formatMessage({
                  id: `fulfillment_inbound.po_table.${
                    isBeforeCutOffTime ? 'before_cutoff' : 'after_cutoff'
                  }`
                })}
              >
                <span
                  className={`cutoff-dot ${
                    isBeforeCutOffTime ? 'before-cutoff' : 'after-cutoff'
                  }`}
                />
              </Tooltip>
            )}
            {formattedDate}, {formattedTime}
          </>
        );
      },
      field: 'updatedAt'
    },
    {
      render: (record) => {
        const menu = menuItems(record);

        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <ActionsIcon className={menu ? 'clickable' : 'dimmed'} />
          </Dropdown>
        );
      }
    }
  ];

  const formatSalesOrders = (salesOrders) =>
    salesOrders.map((order) => ({
      ...order,
      key: order.id
    }));

  const handleGetSalesOrders = async ({
    page,
    limit,
    searchInputText,
    sortField,
    sortOrder
  }) => {
    let value = searchInputText?.trim();
    if (selectedSearchOption === SALES_ORDERS_SEARCH_KEYS.SKU) {
      value = getSkuForScan(value);
    }
    setSearchValue(value);
    setSelectedTrackingNumbers([]);

    const payload = omitBy(
      {
        page,
        limit,
        ...salesOrderFilters,
        ...(value && {
          [selectedSearchOption]: value
        }),
        ...(sortField && {
          sort: sortField,
          order: sortOrder
        })
      },
      isNull
    );

    setSharedPayload(payload);
    setIsFirstFetchDone(true);

    try {
      const {
        data: { salesOrders = [], count = 0 }
      } = await getFulfillmentSalesOrders(payload);
      setSalesOrderCount(count);

      return {
        list: formatSalesOrders(salesOrders),
        total: count
      };
    } catch (error) {
      notify(error.message);
    }
  };

  const handleExportSalesOrders = async () => {
    try {
      const data = await exportFulfillmentSalesOrders(sharedPayload);
      const fileName = `Sales_Orders_${dayjs().format('DD_MM_YYYY')}`;
      downloadAsXlsx(data, fileName);
      notify(
        intl.formatMessage({
          id: 'common.file_downloaded_successfully'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = () => refreshMethod({ pageNumber: 1 });
  };

  const updateTable = () => {
    refreshTable.current();
  };

  const handleSelectProducts = (selectedRows) => {
    setSelectedTrackingNumbers(selectedRows);
  };

  useEffect(() => {
    if (isFirstFetchDone && refreshTable?.current) {
      updateTable();
    }
  }, [salesOrderFilters]);

  useEffect(() => {
    setSelectedTrackingNumbers([]);
  }, [salesOrderFilters, searchValue]);

  return (
    <BRTable
      className="br-fulfillment-table"
      title={
        !selectedTrackingNumbers.length ? (
          intl.formatMessage(
            {
              id: 'fulfillment_outbound.sales_orders_table.title'
            },
            {
              count: salesOrderCount
            }
          )
        ) : (
          <div className="br-sales-orders-table__header-btns">
            <BRButton
              label={intl.formatMessage(
                {
                  id: 'fulfillment_outbound.sales_orders_table.selected'
                },
                {
                  count: selectedTrackingNumbers.length
                }
              )}
              prefixIcon={<UnselectCheckboxIcon />}
              onClick={() => setSelectedTrackingNumbers([])}
            />
            <BRButton
              label={intl.formatMessage({
                id: 'fulfillment_outbound.actions.print_airwaybill'
              })}
              onClick={() =>
                handlePrintAirwaybill({
                  list: selectedTrackingNumbers.map(
                    ({ trackingNumber }) => trackingNumber
                  )
                })
              }
            />
          </div>
        )
      }
      columns={columns}
      listFunction={handleGetSalesOrders}
      shareMethods={acceptMethods}
      searchPlaceholder={intl.formatMessage(
        {
          id: 'fulfillment_outbound.sales_orders_table.search_placeholder'
        },
        {
          searchBy: SALES_ORDERS_SEARCH_OPTIONS.find(
            ({ value }) => value === selectedSearchOption
          ).label
        }
      )}
      exportListFileFunction={handleExportSalesOrders}
      showFilter={false}
      showSearch
      searchOptions={SALES_ORDERS_SEARCH_OPTIONS}
      selectedSearchOption={selectedSearchOption}
      setSelectedSearchOption={setSelectedSearchOption}
      pageSizeOptions={TABLE_PAGE_SIZE_OPTIONS}
      emptySearchContent={{
        title: intl.formatMessage({ id: 'common.no_results_found' }),
        hideAction: !searchValue
      }}
      {...(isSalesOrdersSelectionAllowed(salesOrderFilters?.state) && {
        allowSelection: true,
        handleSelectedRowsData: handleSelectProducts,
        selectedRowKeys: selectedTrackingNumbers.map(({ key }) => key)
      })}
    />
  );
};

export default injectIntl(SalesOrdersTable);
