import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { getFulfillmentReturnToVendor } from 'services/fulfillment-return-to-vendor';
import {
  getViewReturnToVendorContentForRendering,
  renderReturnToVendorTag
} from 'constants/fulfillment-return-to-vendor';

import BusinessInfoCard from 'components/NewFulfillment/FulfillmentOutbound/components/SalesOrder/components/BusinessInfoCard/BusinessInfoCard';
import OrderLogsCard from 'components/NewFulfillment/FulfillmentOutbound/components/SalesOrder/components/OrderLogsCard/OrderLogsCard';
import POHeader from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/POHeader/POHeader';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';
import Products from 'components/NewFulfillment/FulfillmentInbound/components/PurchaseOrder/components/Products/Products';

const ReturnToVendor = ({
  match: {
    params: { id }
  }
}) => {
  const [returnToVendor, setReturnToVendor] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [clickedLineData, setClickedLineData] = useState(null)

  const { formatMessage } = useIntl();

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const { data = {} } = await getFulfillmentReturnToVendor(id);
      setReturnToVendor(data.rtv);
      setProducts(data.rtv?.lines || []);
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  const { productsTableColumns, headerProps = {} } =
    getViewReturnToVendorContentForRendering({
      returnToVendor,
      clickedLineData,
      setClickedLineData
    });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="br-sales-order">
      <LoadingWrapper loading={isLoading}>
        <div>
          <POHeader
            title={formatMessage(
              {
                id: 'fulfillment_common.rtv_id'
              },
              {
                rtvId: returnToVendor.id
              }
            )}
            renderTag={() =>
              renderReturnToVendorTag({
                stateId: returnToVendor.state?.stateId
              })
            }
            {...headerProps}
          />
          <div className="br-po-content">
            <div className="start-side">
              <BusinessInfoCard
                businessInfo={{
                  businessName: returnToVendor.businessName,
                  businessId: returnToVendor.businessId
                }}
              />
              <OrderLogsCard orderLogs={returnToVendor.history?.reverse()} />
            </div>
            <div className="end-side">
              <Products
                products={products}
                columns={productsTableColumns}
                hideSelectProductBtn
                hideOverlay
              />
            </div>
          </div>
        </div>
      </LoadingWrapper>
    </div>
  );
};

export default ReturnToVendor;
